import React, { useRef } from 'react'
import { cx, css, keyframes } from 'emotion'

type Props = {
  children: string
}

const flip = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`

const style = css`
  font-weight: 400;
  position: relative;
  &:after {
    position: absolute;
    left: 0;
    text-align: center;
    content: 'Copied!';
    opacity: 0;
    color: white;
    background-color: var(--blue);
    width: 100%;
    border-radius: 3px;
  }
  &:focus {
    &:after {
    opacity: 1;
    animation-duration: 0.225s;
    animation-name: ${flip};
    }
  }
`

const CopyToClipboard = ({ children }: Props) => {
  const passcodeRef = useRef<HTMLDivElement>(null)

  function copyToClipboard(e: React.SyntheticEvent, text: string) {
    e.preventDefault()
    e.stopPropagation()

    navigator.clipboard.writeText(text)

    if (passcodeRef.current === null) return
    passcodeRef.current.focus()
  }

  return (
    <a href="#top" className={cx(style)} onClick={e => copyToClipboard(e, children)}>
      {children}
    </a>
  )
}

export default CopyToClipboard
