import React from 'react'
import logo from './logo.png'
import CampaignFinishedPage from 'ui/CampaignFinishedPage'

const Finished = () => (
  <CampaignFinishedPage
    title="Sorry, this energy offer for Queensland Cricket has now finished."
    logo={logo}
    dualBrandedLogo
  >
  </CampaignFinishedPage>
)

export default Finished
