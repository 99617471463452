import React from 'react'
import { cx, css } from 'emotion'
import { Text } from '@cx/connect-ui'

type Props = {
  children?: React.ReactNode
  termsAndConditionsUrl?: string
}

const style = css`
  color: #bbb;
  a {
    color: #bbb;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

const CopyrightAndLinks = ({
  children,
  termsAndConditionsUrl = 'https://www.alintaenergy.com.au/help-and-support/terms-and-conditions/',
}: Props) => {
  return (
    <Text className={cx(style)} size="small">
      &copy; 2022 Alinta Energy &nbsp;|&nbsp;&nbsp;
      <a href="https://www.alintaenergy.com.au/help-and-support/terms-and-conditions/privacy-policy/">
        Privacy Policy
      </a>{' '}
      &nbsp;|&nbsp;&nbsp;
      <a href={termsAndConditionsUrl}>Terms &amp; Conditions</a>
    </Text>
  )
}

export default CopyrightAndLinks
