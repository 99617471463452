import { useEffect, useState } from 'react'
import promptForPasscodeIfRequired from './promptForPasscodeIfRequired'
import promptForSalesIdIfRequired, { removeSalesId } from './promptForSalesIdIfRequired'
import { AccessControlType } from './AccessControl'
import { useNavigate } from 'react-router'
import isWithinOperatingHours from './isWithinOperatingHours'
import * as cognito from './cognito'

export const publicPages = ['thanks', 'campaign-finished']

function isPublicPage() {
  const pathname = window.location.pathname
  const urlParts = pathname.split('/')
  return publicPages.includes(urlParts[urlParts.length - 1].toLowerCase())
}

type AccessControlOverrides = {
  accessControlType?: AccessControlType
  cannotOperateBefore?: string
  cannotOperateAfter?: string
  passcode?: string
}

const useAccessControl = ({
  accessControlType,
  cannotOperateBefore,
  cannotOperateAfter,
  passcode,
}: AccessControlOverrides) => {
  const [ready, setReady] = useState(false)

  const navigate = useNavigate()
  useEffect(() => {
    if (!accessControlType || isPublicPage()) {
      setReady(true)
      return
    }
    const interval = setInterval(() => {
      if (isWithinOperatingHours(cannotOperateBefore, cannotOperateAfter)) {
        return
      }

      if (accessControlType === 'salesId') {
        removeSalesId()
        promptForSalesIdIfRequired(navigate)
      } else if (accessControlType === 'cognito' && cognito.isSignedIn()) {
        cognito.logout()
      }
    }, 100)

    switch (accessControlType) {
      case 'salesId':
        promptForSalesIdIfRequired(navigate)
        setReady(true)
        break
      case 'passcode':
        promptForPasscodeIfRequired(navigate, passcode)
        setReady(true)
        break
      case 'cognito':
        if (!cognito.isSignedIn()) {
          cognito.login()
        } else {
          setReady(true)
        }
        break
      default:
        setReady(true)
        break
    }

    return () => clearInterval(interval)
  }, [
    accessControlType,
    passcode,
    navigate,
    cannotOperateBefore,
    cannotOperateAfter,
    setReady,
  ])

  return ready
}

export default useAccessControl
