import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import Loadable from '../../util/Loadable'
import PartnerConfig from '../PartnerConfig'
import logo from './logo.png'
import hero from './hero.jpg'
import brandStyle from './style'
import { CampaignConfig } from '../PartnerConfig'

export const config: CampaignConfig = {
  partner: 'ESG Foundation (Resi)',
  campaignName: 'ESG Foundation (Resi)',
  customerType: 'resi',
  partnerRoute: 'esg',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  logo,
  hero,
  headlineText:
    'Support a better future with our Carbon Balance plan for ESG partners, staff & clients*',
  subHeadlineText:
    'Offset your energy-related carbon emissions for your home with Alinta Energy',
}

// dynamic imports for code-splitting
export const Campaign = Loadable(lazy(() => import('./Campaign')))
export const Thanks = Loadable(lazy(() => import('./Thanks')))
export const Finished = Loadable(lazy(() => import('./Finished')))
const smeRoute = 'esg-sme'

const CampaignPages = () => (
  <PartnerConfig config={config} branding={brandStyle}>
    <Routes>
      <Route
        path={config.campaignRoute}
        element={<Campaign config={config} smeRoute={smeRoute} />}
      />
      <Route path={config.thanksRoute} element={<Thanks />} />
      <Route path={config.campaignFinishedRoute} element={<Finished config={config} />} />
    </Routes>
  </PartnerConfig>
)

export default CampaignPages
