import { CampaignConfig } from '../../PartnerConfig'
import hero from '../template-sme/hero.png'

export const smeConfig: CampaignConfig = {
  partner: 'Kiosk Friends & Family (SME)',
  campaignName: 'AE-Kiosk-FF-SME',
  customerType: 'sme',
  partnerRoute: 'affinity-ff-sme',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  logo: '',
  hero: hero,
  getSalesName: () => 'CCCFandF',
  signupFormType: 'FullAddress',
  headlineText:  'Discover a better energy deal with Alinta Energy',
  subHeadlineText: 'Enter your postcode to unlock savings today!',
  termsText: '',
}
