import React from 'react'
import { cx, css } from 'emotion'
import Button from './Button'
import { PrimaryButton } from '@cx/connect-ui'

type Props = {
  children?: React.ReactNode
  className?: string,
  href: string
  color?: 'primaryBlue' | 'primaryOrange'
  icon?: string
  renderRight?: () => React.ReactNode
}

const style = css`
  display: inline-block;
  &:hover {
    text-decoration: none;
  }
`

const LinkButton = ({ children, href, className, color = 'primaryOrange', renderRight }: Props) => {
  return (
    <a className={cx(className, style)} href={href}>
      <PrimaryButton color={color} renderRight={renderRight}>{children}</PrimaryButton>
    </a>
  )
}

export default LinkButton
