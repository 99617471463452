import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import AccessControlApp from './access-control/AccessControl'
import { ThemeContext, ThemeFontInjector, fonts } from '@cx/connect-ui'
import Directory from './directory/PartnerDirectory'
import ErrorBoundary from './util/ErrorBoundary'

import Acme, { config as acmeConfig } from './partners/acme/Index'
import Acn, { config as acnConfig } from './partners/acn/Index'
import ConnectAssist, {
  config as connectAssistConfig,
} from './partners/connect-assist/Index'
import CricketAustralia, {
  config as cricketAustraliaConfig,
} from './partners/cricket-australia/Index'
import IcbBodyCorporate, {
  config as icbBodyCorpConfig,
} from './partners/icb-body-corp/Index'
import Qendo, { config as qendoConfig } from './partners/qendo/Index'
import Easi, { config as easiConfig } from './partners/easi/Index'
import Anz, { config as anzConfig } from './partners/anz/Index'

import Uniti, { config as unitiConfig } from './partners/uniti/Index'
import UnitiQld, { config as unitiConfigQld } from './partners/uniti-qld/Index'
import UnitiNsw, { config as unitiConfigNsw } from './partners/uniti-nsw/Index'
import UnitiVic, { config as unitiConfigVic } from './partners/uniti-vic/Index'
import UnitiSa, { config as unitiConfigSa } from './partners/uniti-sa/Index'
import {
  ResiCampaignPages as TamhaResi,
  SmeCampaignPages as TamhaSme,
  resiConfig as tamhaResiConfig,
  smeConfig as tamhaSmeConfig,
} from './partners/tamha/Index'
import { smeConfig as qantasConfig } from './partners/qantas/Index'
import QantasRedirectToAlinta from './partners/qantas/RedirectToAlinta'

import EasiSme, { smeConfig as easiConfigSme } from './partners/easi-sme/Index'
import AlintaKioskWithCognito, {
  config as alintaKioskWithCognitoConfig,
} from './partners/alinta-kiosk-with-cognito/Index'
import AlintaKioskSmeWithCognito, {
  config as alintaKioskSmeWithCognitoConfig,
} from './partners/alinta-kiosk-sme-with-cognito/Index'
import AlintaKiosk, { config as alintaKioskConfig } from './partners/alinta-kiosk/Index'
import AlintaKioskSme, {
  config as alintaKioskSmeConfig,
} from './partners/alinta-kiosk-sme/Index'
import QldCricketResi, {
  config as qldCricketResiConfig,
} from './partners/qld-cricket/Index'
import GippslandResi, {
  config as gippslandResiConfig,
} from './partners/gippsland-resi/Index'
import GippslandSme, {
  config as gippslandSmeConfig,
} from './partners/gippsland-sme/Index'
import EsgCarbonResi, { config as esgCarbonResiConfig } from './partners/esg/Index'
import EsgCarbonSme, { config as esgCarbonSmeConfig } from './partners/esg-sme/Index'

// Brokers Start
import BrokerTemplate from './partners/brokers/template/Index'
import SmeBrokerTemplate from './partners/brokers/template-sme/Index'

import { config as icbConfig } from './partners/brokers/icb/config'
import { config as bulkEnergyConfig } from './partners/brokers/bulk-energy/config'
import { config as dolphinEnergyConfig } from './partners/brokers/dolphin-energy/config'
import { config as choiceEnergyConfig } from './partners/brokers/choice-energy/config'
import { config as energyIntelligenceConfig } from './partners/brokers/energy-intelligence/config'
import { config as forNrgConfig } from './partners/brokers/fornrg/config'
import { config as leadingEdgeEnergyConfig } from './partners/brokers/leading-edge-energy/config'
import { config as enerComConfig } from './partners/brokers/enercom/config'
import { config as ripperDealsConfig } from './partners/brokers/ripper-deals/config'
import { config as specialistEnergyManConfig } from './partners/brokers/specialist-energy-management/config'
import { config as susEnergySolConfig } from './partners/brokers/sustainable-energy-solutions/config'
import { config as strataEnergySolConfig } from './partners/brokers/strata-energy-services/config'
import { config as hellenicPowerConfig } from './partners/brokers/hellenic-power/config'
import { config as emsConfig } from './partners/brokers/ems/config'
import { config as ellasListConfig } from './partners/brokers/ellas-list/config'
import { config as energyAllianceConfig } from './partners/brokers/energy-alliance/config'
import NotFoundPage from 'failure/NotFoundPage'
import { resiConfig as utilityHubResiConfig } from './partners/brokers/utility-hub/resiConfig'
import { smeConfig as utilityHubSmeConfig } from './partners/brokers/utility-hub/smeConfig'
import { resiConfig as kioskFriendsAndFamilyResiConfig } from './partners/brokers/kiosk-friends-and-family/resiConfig'
import { smeConfig as kioskFriendsAndFamilySmeConfig } from './partners/brokers/kiosk-friends-and-family/smeConfig'
import { smeConfig as qldCricketSmeConfig } from './partners/brokers/qld-cricket/smeConfig'
import { resiConfig as edgeUtilitiesResiConfig } from './partners/brokers/edge-utilities/resiConfig'
import { resiConfig as beevoResiConfig } from './partners/brokers/beevo/resiConfig'

// Generic Alinta
import ResiAlintaTemplate from './partners/generic-alinta/template-resi/Index'
import SmeAlintaNswQldTemplate from './partners/generic-alinta/template-sme-nsw-qld/Index'
import SmeAlintaSaVicTemplate from './partners/generic-alinta/template-sme-sa-vic/Index'

import { resiConfig as mooneeResiConfig } from './partners/generic-alinta/moonee/resiConfig'
import { smeNswQldConfig as mooneeSmeNswQldConfig } from './partners/generic-alinta/moonee/smeNswQldConfig'
import { smeSaVicConfig as mooneeSmeSaVicConfig } from './partners/generic-alinta/moonee/smeSaVicConfig'
import { config as apodConfig } from './partners/generic-alinta/apod/config'

import LoggedOutPage from 'access-control/LoggedOutPage'
import { ALINTA_STOP_SALE } from 'featureToggles'
import StoppedSalePage from 'ui/StoppedSalePage'

export const partnerRoute = 'partner'

const partnerRoutes = (
  <>
    <Route path={`${partnerRoute}/*`}>
      <Route path={`${acmeConfig.partnerRoute}/*`} element={<Acme />} />
      <Route path={`${acnConfig.partnerRoute}/*`} element={<Acn />} />
      <Route path={`${connectAssistConfig.partnerRoute}/*`} element={<ConnectAssist />} />
      <Route
        path={`${cricketAustraliaConfig.partnerRoute}/*`}
        element={<CricketAustralia />}
      />
      <Route
        path={`${icbBodyCorpConfig.partnerRoute}/*`}
        element={<IcbBodyCorporate />}
      />
      <Route
        path={`${alintaKioskWithCognitoConfig.partnerRoute}/*`}
        element={<AlintaKioskWithCognito />}
      />
      <Route
        path={`${alintaKioskSmeWithCognitoConfig.partnerRoute}/*`}
        element={<AlintaKioskSmeWithCognito />}
      />
      <Route path={`${alintaKioskConfig.partnerRoute}/*`} element={<AlintaKiosk />} />
      <Route
        path={`${alintaKioskSmeConfig.partnerRoute}/*`}
        element={<AlintaKioskSme />}
      />
      <Route path={`${qendoConfig.partnerRoute}/*`} element={<Qendo />} />
      <Route path={`${tamhaSmeConfig.partnerRoute}/*`} element={<TamhaSme />} />
      <Route path={`${tamhaResiConfig.partnerRoute}/*`} element={<TamhaResi />} />
      <Route
        path={`${qantasConfig.partnerRoute}/*`}
        element={<QantasRedirectToAlinta />}
      />
      <Route path={`${easiConfigSme.partnerRoute}/*`} element={<EasiSme />} />
      <Route path={`${easiConfig.partnerRoute}/*`} element={<Easi />} />
      <Route path={`${anzConfig.partnerRoute}/*`} element={<Anz />} />
      <Route
        path={`${icbConfig.partnerRoute}/*`}
        element={<BrokerTemplate config={icbConfig} />}
      />
      <Route path={`${unitiConfigQld.partnerRoute}/*`} element={<UnitiQld />} />
      <Route path={`${unitiConfigNsw.partnerRoute}/*`} element={<UnitiNsw />} />
      <Route path={`${unitiConfigVic.partnerRoute}/*`} element={<UnitiVic />} />
      <Route path={`${unitiConfigSa.partnerRoute}/*`} element={<UnitiSa />} />
      <Route path={`${unitiConfig.partnerRoute}/*`} element={<Uniti />} />
      <Route
        path={`${bulkEnergyConfig.partnerRoute}/*`}
        element={<BrokerTemplate config={bulkEnergyConfig} />}
      />
      <Route
        path={`${dolphinEnergyConfig.partnerRoute}/*`}
        element={<BrokerTemplate config={dolphinEnergyConfig} />}
      />
      <Route
        path={`${choiceEnergyConfig.partnerRoute}/*`}
        element={<BrokerTemplate config={choiceEnergyConfig} />}
      />{' '}
      <Route
        path={`${energyIntelligenceConfig.partnerRoute}/*`}
        element={<BrokerTemplate config={energyIntelligenceConfig} />}
      />
      <Route
        path={`${forNrgConfig.partnerRoute}/*`}
        element={<BrokerTemplate config={forNrgConfig} />}
      />
      <Route
        path={`${leadingEdgeEnergyConfig.partnerRoute}/*`}
        element={<BrokerTemplate config={leadingEdgeEnergyConfig} />}
      />
      <Route
        path={`${enerComConfig.partnerRoute}/*`}
        element={<BrokerTemplate config={enerComConfig} />}
      />
      <Route
        path={`${ripperDealsConfig.partnerRoute}/*`}
        element={<BrokerTemplate config={ripperDealsConfig} />}
      />
      <Route
        path={`${specialistEnergyManConfig.partnerRoute}/*`}
        element={<BrokerTemplate config={specialistEnergyManConfig} />}
      />
      <Route
        path={`${susEnergySolConfig.partnerRoute}/*`}
        element={<BrokerTemplate config={susEnergySolConfig} />}
      />
      <Route
        path={`${strataEnergySolConfig.partnerRoute}/*`}
        element={<BrokerTemplate config={strataEnergySolConfig} />}
      />
      <Route
        path={`${hellenicPowerConfig.partnerRoute}/*`}
        element={<BrokerTemplate config={hellenicPowerConfig} />}
      />
      <Route
        path={`${emsConfig.partnerRoute}/*`}
        element={<BrokerTemplate config={emsConfig} />}
      />
      <Route
        path={`${ellasListConfig.partnerRoute}/*`}
        element={<BrokerTemplate config={ellasListConfig} />}
      />
      <Route
        path={`${utilityHubSmeConfig.partnerRoute}/*`}
        element={
          <SmeBrokerTemplate
            config={utilityHubSmeConfig}
            resiRoute={utilityHubResiConfig.partnerRoute}
          />
        }
      />
      <Route
        path={`${utilityHubResiConfig.partnerRoute}/*`}
        element={
          <BrokerTemplate
            config={utilityHubResiConfig}
            smeRoute={utilityHubSmeConfig.partnerRoute}
          />
        }
      />
      <Route
        path={`${kioskFriendsAndFamilySmeConfig.partnerRoute}/*`}
        element={
          <SmeBrokerTemplate
            config={kioskFriendsAndFamilySmeConfig}
            resiRoute={kioskFriendsAndFamilyResiConfig.partnerRoute}
          />
        }
      />
      <Route
        path={`${kioskFriendsAndFamilyResiConfig.partnerRoute}/*`}
        element={
          <BrokerTemplate
            config={kioskFriendsAndFamilyResiConfig}
            smeRoute={kioskFriendsAndFamilySmeConfig.partnerRoute}
          />
        }
      />
      <Route
        path={`${energyAllianceConfig.partnerRoute}/*`}
        element={<BrokerTemplate config={energyAllianceConfig} />}
      />
      <Route
        path={`${qldCricketSmeConfig.partnerRoute}/*`}
        element={
          <SmeBrokerTemplate
            config={qldCricketSmeConfig}
            resiRoute={qldCricketResiConfig.partnerRoute}
          />
        }
      />
      <Route path={`${gippslandSmeConfig.partnerRoute}/*`} element={<GippslandSme />} />
      <Route
        path={`${qldCricketResiConfig.partnerRoute}/*`}
        element={<QldCricketResi />}
      />
      <Route path={`${gippslandResiConfig.partnerRoute}/*`} element={<GippslandResi />} />
      <Route
        path={`${edgeUtilitiesResiConfig.partnerRoute}/*`}
        element={<BrokerTemplate config={edgeUtilitiesResiConfig} />}
      />
      <Route
        path={`${beevoResiConfig.partnerRoute}/*`}
        element={<BrokerTemplate config={beevoResiConfig} />}
      />
      <Route
        path={`${mooneeSmeNswQldConfig.partnerRoute}/*`}
        element={
          <SmeAlintaNswQldTemplate
            config={mooneeSmeNswQldConfig}
            resiRoute={mooneeResiConfig.partnerRoute}
            smeSaVicRoute={mooneeSmeSaVicConfig.partnerRoute}
          />
        }
      />
      <Route
        path={`${mooneeSmeSaVicConfig.partnerRoute}/*`}
        element={
          <SmeAlintaSaVicTemplate
            config={mooneeSmeSaVicConfig}
            resiRoute={mooneeResiConfig.partnerRoute}
            smeNswQldRoute={mooneeSmeNswQldConfig.partnerRoute}
          />
        }
      />
      <Route
        path={`${mooneeResiConfig.partnerRoute}/*`}
        element={
          <ResiAlintaTemplate
            config={mooneeResiConfig}
            smeNswQldRoute={mooneeSmeNswQldConfig.partnerRoute}
            smeSaVicRoute={mooneeSmeSaVicConfig.partnerRoute}
          />
        }
      />
      <Route
        path={`${apodConfig.partnerRoute}/*`}
        element={<ResiAlintaTemplate config={apodConfig} />}
      />
      {/* <Route
        path={`${esgSmeConfig.partnerRoute}/*`}
        element={
          <SmeBrokerTemplate
            config={esgSmeConfig}
            resiRoute={esgResiConfig.partnerRoute}
          />
        }
      />
      <Route
        path={`${esgResiConfig.partnerRoute}/*`}
        element={
          <BrokerTemplate config={esgResiConfig} smeRoute={esgSmeConfig.partnerRoute} />
        }
      /> */}
      <Route path={`${esgCarbonSmeConfig.partnerRoute}/*`} element={<EsgCarbonSme />} />
      <Route path={`${esgCarbonResiConfig.partnerRoute}/*`} element={<EsgCarbonResi />} />
    </Route>
    <Route path="/partner*" element={<NotFoundPage />} />
  </>
)

const offlineRoute = '/offline'

const App = () => {
  return (
    <React.StrictMode>
      <ErrorBoundary>
        <ThemeContext.Provider value="alinta">
          <Router>
            <Routes>
              <Route path="access-control" element={<AccessControlApp />} />
              {ALINTA_STOP_SALE ? (
                <Route
                  path={`${partnerRoute}/*`}
                  element={<Navigate replace to={offlineRoute} />}
                />
              ) : (
                partnerRoutes
              )}

              <Route path={offlineRoute} element={<StoppedSalePage />} />

              <Route path="campaigns/directory/*" element={<Directory />} />
              <Route path="/auth/loggedOut" element={<LoggedOutPage />} />
              <Route path="/*" element={<NotFoundPage />} />
            </Routes>
          </Router>

          <ThemeFontInjector theme="alinta" fonts={fonts} />
        </ThemeContext.Provider>
      </ErrorBoundary>
    </React.StrictMode>
  )
}

export default App
