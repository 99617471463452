import { isWithinInterval, set } from 'date-fns'

function isWithinOperatingHours(cannotOperateBefore?: string, cannotOperateAfter?: string) {
  if (!cannotOperateBefore || !cannotOperateAfter) {
    return true
  }

  const startTimeHour = Number(cannotOperateBefore.split(':')[0])
  const startTimeMin = Number(cannotOperateBefore.split(':')[1])

  const endTimeHour = Number(cannotOperateAfter.split(':')[0])
  const endTimeMin = Number(cannotOperateAfter.split(':')[1])

  const today = new Date()
  const start = set(today, {
    hours: startTimeHour,
    minutes: startTimeMin,
    seconds: 0,
    milliseconds: 0,
  })
  const end = set(today, {
    hours: endTimeHour,
    minutes: endTimeMin,
    seconds: 0,
    milliseconds: 0,
  })

  return isWithinInterval(today, { start, end })
}

export default isWithinOperatingHours
