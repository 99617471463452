import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import Loadable from '../../util/Loadable'
import PartnerConfig from '../PartnerConfig'
import logo from './logo.png'
import hero from './hero.jpg'
import brandStyle from './style'
import { CampaignConfig } from '../PartnerConfig'
import Finished from './Finished'

export const config: CampaignConfig = {
  partner: 'acn',
  campaignName: 'acn',
  customerType: 'resi',
  partnerRoute: 'acn',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  logo,
  hero,
}

// dynamic imports for code-splitting
export const Thanks = Loadable(lazy(() => import('./Thanks')))

const CampaignPages = () => (
  <PartnerConfig config={config} branding={brandStyle}>
    <Routes>
      <Route path={config.thanksRoute} element={<Thanks />} />
      <Route path={config.campaignFinishedRoute} element={<Finished />} />
    </Routes>
  </PartnerConfig>
)

export default CampaignPages
