import { NavigateFunction } from 'react-router'
import { accessControlRoute, ACCESS_CODE_PARTIAL_KEY } from './AccessControl'

function redirectToAccessControl(navigate: NavigateFunction, requestedPage: string, hash: string) {
  const { pathname } = window.location
  navigate(`/${accessControlRoute}?page=${requestedPage}&returnTo=${pathname}&hash=${hash}`, { replace: true })
}

function promptForPasscodeIfRequired(navigate: NavigateFunction, passcode: string | undefined) {
  let urlParts = window.location.pathname.split('/')
  let requestedPage = urlParts.slice(2).join('/')

  if (passcode === undefined) {
    return
  }

  const storedPasscode = localStorage.getItem(
    `${ACCESS_CODE_PARTIAL_KEY}${requestedPage}`
  )

  if (storedPasscode !== passcode) {
    redirectToAccessControl(navigate, requestedPage, passcode)
  }
}

export default promptForPasscodeIfRequired
