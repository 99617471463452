import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import Loadable from '../../util/Loadable'
import PartnerConfig from '../PartnerConfig'
import logo from './logo.png'
import smeHero from './hero-sme.jpg'
import smeBranding from './sme.style'
import { CampaignConfig } from '../PartnerConfig'

export const smeConfig: CampaignConfig = {
  partner: 'Easi (SME)',
  campaignName: 'Easi-SME',
  customerType: 'sme',
  passcode: 'easi-nrgie',
  partnerRoute: 'easi-sme',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  logo,
  hero: smeHero,
}

// dynamic imports for code-splitting
export const SmeCampaign = Loadable(lazy(() => import('./SmeCampaign')))
export const SmeThanks = Loadable(lazy(() => import('./SmeThanks')))

const CampaignPages = () => (
  <PartnerConfig config={smeConfig} branding={smeBranding}>
    <Routes>
      <Route path={smeConfig.campaignRoute} element={<SmeCampaign />} />
      <Route path={smeConfig.thanksRoute} element={<SmeThanks />} />
    </Routes>
  </PartnerConfig>
)

export default CampaignPages
