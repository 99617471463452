import React, { useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { cx, css } from 'emotion'
import { Inline, Padding, Spacer } from '@cx/connect-ui'
import { responsive, tiny, small } from '@cx/connect-ui/lib/lib/responsive'
import md5 from 'md5'
import useAccessControl from '../access-control/useAccessControl'
import Banner from '../ui/Banner'
import pendingIcon from '../img/icons/status-pending.svg'
import futureIcon from '../img/icons/status-future.svg'
import approvedIcon from '../img/icons/status-approved.svg'
import onholdIcon from '../img/icons/status-onhold.svg'
import closedIcon from '../img/icons/status-closed.svg'
import IconTab from './IconTab'
import Tiles from './Tiles'
import Heading from './Heading'
import campaigns from './campaignsByStatus'

const style = css`
  color: #57698177;
  min-height: 100%;
  font-size: 15px;
  background-image: linear-gradient(to top, #becddf 0, #e4e9f0 100%);
  text-align: center;
  padding-bottom: 128px;
`

const partnerDirectoryPasscode = 'charlie-delta'

const PartnerDirectory = () => {
  useAccessControl({
    accessControlType: 'passcode',
    passcode: md5(partnerDirectoryPasscode),
  })
  const navigate = useNavigate()

  useEffect(() => {
    // react-router-dom v6 removed <Redirect />,
    // so we have to handle the redirect here
    const { pathname } = window.location
    if (pathname === '/campaigns/directory' || pathname === '/campaigns/directory/') {
      navigate('pending')
    }
  }, [navigate])

  return (
    <div className={cx(style)}>
      <Banner />
      <Heading />
      <Padding size="large">
        Each partner page is secured by the access code shown on its tile below.
        <br /> Click any access code to copy it to your clipboard.
      </Padding>
      <Spacer height={32} />

      <Inline spacing={responsive(tiny('generous'), small('giant'))}>
        <IconTab
          to="pending"
          label="Pending"
          icon={pendingIcon}
          count={campaigns.pending.length}
        />
        <IconTab
          to="future"
          label="Future?"
          icon={futureIcon}
          count={campaigns.future.length}
        />
        <IconTab
          to="approved"
          label="Approved"
          icon={approvedIcon}
          count={campaigns.approved.length}
        />
        <IconTab
          to="onhold"
          label="On Hold"
          icon={onholdIcon}
          count={campaigns.onhold.length}
        />
        <IconTab
          to="closed"
          label="Closed"
          icon={closedIcon}
          count={campaigns.closed.length}
        />
      </Inline>
      <Spacer height={64} />

      <Routes>
        <Route path="pending" element={<Tiles items={campaigns.pending} />} />
        <Route path="future" element={<Tiles items={campaigns.future} />} />
        <Route path="approved" element={<Tiles items={campaigns.approved} />} />
        <Route path="onhold" element={<Tiles items={campaigns.onhold} />} />
        <Route path="closed" element={<Tiles items={campaigns.closed} />} />
      </Routes>
    </div>
  )
}

export default PartnerDirectory
