import { CampaignConfig } from '../../PartnerConfig'
import logo from './specialist-energy-management.png'
import hero from '../template/hero.jpg'

export const config: CampaignConfig = {
  partner: 'Specialist Energy Management',
  campaignName: 'SpecialistEnergyManagement',
  customerType: 'resi',
  partnerRoute: 'specialist-energy-management',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  logo,
  hero,
  getSalesName: () => 'SPECEMCD',
  headlineText: 'Check out this great energy deal for Specialist Energy Management*',
  subHeadlineText: 'In partnership with Alinta Energy',
}
