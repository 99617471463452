import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import Loadable from '../../util/Loadable'
import PartnerConfig from '../PartnerConfig'
import logo from './logo-wide.png'
import smeHero from './hero-sme.jpeg'
import { CampaignConfig } from '../PartnerConfig'
import smeStyle from './sme.style'
import { defaultPrimaryButtonColor, tablet } from 'variables.style'
import { css, cx } from 'emotion'
import LinkButton from 'ui/LinkButton'
import RightChevron from 'ui/RightChevron'

export const smeConfig: CampaignConfig = {
  partner: 'Qantas Business Rewards',
  campaignName: 'QBR SME',
  customerType: 'sme',
  partnerRoute: 'qbr',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  logo,
  hero: smeHero,
}

export const SmeCampaign = Loadable(lazy(() => import('./SmeCampaign')))
export const SmeThanks = Loadable(lazy(() => import('./SmeThanks')))
export const Finished = Loadable(lazy(() => import('../TemporaryClosed')))

export const linkStyle = css`
  --button-secondary: ${defaultPrimaryButtonColor};
  > .cui_primary-button {
    font-weight: normal;
    min-width: auto;
    width: 220px;
    @media screen and (min-width: ${tablet}) {
      width: 220px;
    }
  }
`

const mainButtonOnThanksPage = (
  <LinkButton
    className={cx(linkStyle)}
    color="primaryOrange"
    href="https://forms.alintaenergy.com.au/forms/qbr"
    renderRight={() => <RightChevron />}
  >
    Let me know
  </LinkButton>
)

const CampaignPages = () => (
  <PartnerConfig config={smeConfig} branding={smeStyle(smeConfig.hero)}>
    <Routes>
      <Route path={smeConfig.campaignRoute} element={<SmeCampaign />} />
      <Route path={smeConfig.thanksRoute} element={<SmeThanks />} />
      <Route
        path={smeConfig.campaignFinishedRoute}
        element={
          <Finished
            config={smeConfig}
            title={'This energy offer is currently not available for new customers'}
            mainBody={
              <>
                We’re busy working on a new Alinta Energy Qantas Business Rewards energy
                offer for our small business customers. If you’re already on the
                BusinessDeal Qantas energy offer you will continue to earn Qantas Points.
                <br />
                <br />
                Be sure to visit{' '}
                <a
                  href="https://www.alintaenergy.com.au"
                  className={css`
                    color: rgb(50, 121, 199);
                  `}
                >
                  www.alintaenergy.com.au
                </a>{' '}
                over the coming months to see when a new Alinta Energy Qantas Business
                Rewards energy plan is next available.
                <br />
                <br />
                Or, click on the button below to leave your business name, ABN and your
                best business contact name and email address, and Alinta Energy will reach
                out as soon as a new Qantas Points earning offer is available.
              </>
            }
            mainButton={mainButtonOnThanksPage}
          />
        }
      />
    </Routes>
  </PartnerConfig>
)

export default CampaignPages
