import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import Loadable from '../../util/Loadable'
import PartnerConfig from '../PartnerConfig'
import logo from './logo.png'
import hero from './hero.jpg'
import brandStyle from './style'
import { CampaignConfig } from '../PartnerConfig'

export const config: CampaignConfig = {
  partner: 'UNITI',
  campaignName: 'uniti',
  customerType: 'resi',
  partnerRoute: 'uniti',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  logo,
  hero,
}

// dynamic imports for code-splitting
export const Campaign = Loadable(lazy(() => import('./Campaign')))
export const Thanks = Loadable(lazy(() => import('./Thanks')))
export const Finished = Loadable(lazy(() => import('../TemporaryClosed')))

const CampaignPages = () => (
  <PartnerConfig config={config} branding={brandStyle}>
    <Routes>
      <Route path={config.campaignRoute} element={<Campaign />} />
      <Route path={config.thanksRoute} element={<Thanks />} />
      <Route path={config.campaignFinishedRoute} element={<Finished config={config} />} />

    </Routes>
  </PartnerConfig>
)

export default CampaignPages
