import { css } from 'emotion'

const smeStyle = (hero: string) => css`
  --brand1: #ed7722; // orange
  --brand2: #4384ce; // blue
  --brand3: #f5f6f6; // off-white
  --brand4: #dfe0e2;
  --brand5: #737271;
  --black: #474443;

  --headings: var(--black);
  --subheadings: var(--black);
  --icon: var(--brand1);
  --icon-border: var(--brand4);
  --hero-bg: url(${hero});
  --awards-section-bg: var(--brand3);
  --features-bg: white;
  --feature-heading: var(--brand5);
  --banner-strip-bg: var(--brand2);
  --signup-bg: var(--brand2);
  --thanks-left-bg: white;
  --thanks-page-bg: url(${hero});

  --button-primary: var(--brand2);
  --button-secondary: var(--brand1);
  --link: var(--brand1);
`

export default smeStyle
