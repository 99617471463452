import { getConfig } from 'config'

const appConfig = getConfig()

const redirectToAlinta = () => {
  window.location.replace(appConfig.qantasRedirectUrl)
  return null
}

export default redirectToAlinta
