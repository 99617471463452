import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import logo from './logo.png'
import CampaignFinishedPage from 'ui/CampaignFinishedPage'

const RedirectToCampaign = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/partner/icb/')
  }, [navigate])

  return <div />
}

const Finished = () => (
  <CampaignFinishedPage
    title="Sorry, this energy offer has now finished."
    logo={logo}
    dualBrandedLogo
  >
    <RedirectToCampaign />
  </CampaignFinishedPage>
)

export default Finished
