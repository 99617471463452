import { css } from 'emotion'
import hero from './hero.jpg'
import thanksHero from './thanks-hero.jpg'

export default css`
  --brand1: #f26722;
  --brand2: #3279c7;
  --brand3: #3e3c3b;
  --brand4: #f4f4f5;

  --headings: var(--brand3);
  --subheadings: var(--black);
  --icon: var(--brand1);
  --form-icon: var(--brand1);
  --placeholder-text: var(--brand3);
  --hero-bg: url(${hero});
  --features-bg: var(--brand4);
  --feature-heading: var(--brand3);
  --signup-bg: var(--brand2);
  --thanks-left-bg: white;
  --thanks-page-bg: url(${thanksHero});
  --thanks-bg-offset: 80%;

  --button-primary: var(--brand1);
  --button-secondary: var(--brand2);
  --link: var(--brand5);

  #signup .cui_primary-button {
    background-color: var(--button-primary);
    &:hover {
      background-color: var(--button-primary);
    }
    &:active,
    &:focus {
      background-color: var(--button-primary);
    }
  }
`
