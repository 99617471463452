import React from 'react'
import logo from '../img/logo-alinta-wide.png'
import { Spacer, Stack } from '@cx/connect-ui'
import { cx, css } from 'emotion'

const style = css`
  display: flex;
  flex-direction: column;
  h3 {
    text-align: center;
  }
`

const LoggedOutPage = () => {
  return (
    <div className={cx(style)}>
      <Stack align="center">
        <Spacer height={16} />
        {logo && <img src={logo} alt="Alinto logo" />}
        <Spacer height={16} />
      </Stack>
      <h3>Successfully logged out!</h3>
    </div>
  )
}

export default LoggedOutPage
