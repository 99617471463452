import { CampaignConfig } from '../../PartnerConfig'
import logo from './logo.png'
import hero from '../template/hero.jpg'

export const resiConfig: CampaignConfig = {
  partner: 'Edge Utilities',
  campaignName: 'Edge Utilities',
  customerType: 'resi',
  partnerRoute: 'edge-utilities',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  logo,
  hero,
  getSalesName: () => 'EDGECD',
  headlineText: 'Discover a better energy deal with Alinta Energy*',
  subHeadlineText: 'Enter your postcode to unlock savings today!',
}
