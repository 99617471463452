import { NavigateFunction } from 'react-router'
import { getConfig } from 'config'
import { accessControlRoute, AccessControlType, SALES_ID_KEY, SALES_ID_SIGNED_IN_AT_KEY } from './AccessControl'
import { addMinutes, parseJSON } from 'date-fns'
import SalesIds from './SalesIds.json'

export function verifySalesId(salesId: string) {
  return SalesIds.includes(salesId)
}

function verifyExpiry(signedInAt: string, durationInMinutes: number) {
  if (!signedInAt) return false

  const today = new Date()
  const parsed = parseJSON(signedInAt)
  const expiry = addMinutes(parsed, durationInMinutes)
  return expiry > today
}

function redirectToAccessControl(navigate: NavigateFunction, requestedPage: string) {
  const { pathname } = window.location
  const type: AccessControlType = 'salesId'
  navigate(`/${accessControlRoute}?page=${requestedPage}&returnTo=${pathname}&type=${type}`, { replace: true })
}

export function getSalesId() {
  return localStorage.getItem(SALES_ID_KEY) || ""
}
export function removeSalesId() {
  if (getSalesId() !== "") {
    localStorage.removeItem(SALES_ID_KEY)
  }
}

function getSignedInAt() {
  return localStorage.getItem(SALES_ID_SIGNED_IN_AT_KEY) || ""
}

function promptForSalesIdIfRequired(navigate: NavigateFunction) {
  const storedSalesId = getSalesId()
  const signedInAt = getSignedInAt()

  if (
    verifySalesId(storedSalesId) &&
    verifyExpiry(signedInAt, getConfig().salesIdExpiryInMinutes)
  ) {
    return
  }

  redirectToAccessControl(navigate, window.location.pathname)
}

export default promptForSalesIdIfRequired
