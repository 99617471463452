import { CampaignConfig } from '../../PartnerConfig'

import hero from '../template-resi/hero.jpg'

export const config: CampaignConfig = {
  partner: 'APOD',
  campaignName: 'APOD',
  customerType: 'resi',
  partnerRoute: 'apod',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  hero,
  logo: '',
  getSalesName: () => '02APOD',
  headlineText: 'Check out this competitive energy deal for Generic AFF (APOD)*',
  subHeadlineText: 'In partnership with Alinta Energy',
  termsText: '',
}
