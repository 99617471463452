import { CampaignConfig } from '../../PartnerConfig'
import logo from './dolphin-energy.png'
import hero from '../template/hero.jpg'

export const config: CampaignConfig = {
  partner: 'Dolphin Energy',
  campaignName: 'DolphinEnergy',
  customerType: 'resi',
  partnerRoute: 'dolphin-energy',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  logo,
  hero,
  getSalesName: () => 'DOLENECD',
  headlineText: 'Check out this great energy deal for Dolphin-Energy*',
  subHeadlineText: 'In partnership with Alinta Energy',
}
