import { CampaignConfig } from '../../PartnerConfig'

import hero from '../template-sme-sa-vic/hero.jpg'

export const smeSaVicConfig: CampaignConfig = {
  partner: 'Moonee SME SA VIC',
  campaignName: 'Moonee SME SA VIC',
  customerType: 'sme',
  partnerRoute: 'moonee-sme-sa-vic',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  hero,
  logo: '',
  getSalesName: () => '01Moonee',
  headlineText: 'Check out this competitive energy deal for your business*',
  subHeadlineText: 'Discover a great energy deal with Alinta Energy',
  termsText: '',
}
