import React from 'react'
import { cx, css, keyframes } from 'emotion'
import { Link, useNavigate } from 'react-router-dom'
import { Inline, GhostButton, Spacer, Title } from '@cx/connect-ui'
import { partnerRoute as globalPartnerRoute } from '../App'
import { wide } from '../variables.style'
import CopyToClipboard from './CopyToClipboard'
import { tablet } from '../variables.style'
import alintaLogo from '../img/alinta-logo.png'

export type Props = {
  partner: string
  partnerRoute: string
  passcode?: string
  campaignRoute: string
  thanksRoute: string
  logo?: string
  hero: string
}

const fadein = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`

const style = css`
  animation-duration: 0.425s;
  animation-name: ${fadein};
  color: var(--black);
    height: 270px;
    width: 270px;
  font-size: 13px;
  background-color: white;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 5px 11px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.1s ease-out;
  @media screen and (min-width: ${tablet}) {
    display: flex;
    height: 160px;
    width: 100%;
  }
  @media screen and (min-width: ${wide}) {
    display: block;
    height: 270px;
    width: 270px;
  }
  &:hover {
    background-color: var(--blue);
    color: white;
    box-shadow: 0 5px 11px rgba(0,0,0,.3);
    a {
      color: white;
    }
  }
`

const imageStyle = (hero: string) => css`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 160px;
  background-image: url(${hero});
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
@media screen and (min-width: ${tablet}) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0;
  width: 40%;
  height: 100%;
}
@media screen and (min-width: ${wide}) {
  width: 100%;
  height: 160px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
}
  img {
    object-fit: contain;
    max-width: 60%;
    max-height: 65%;
  }
  &:hover {
    cursor: pointer;
  }
`

const contentStyle = css`
    padding-top: 20px;
  text-align: center;
  @media screen and (min-width: ${tablet}) {
    padding: 60px 20px;
    text-align: left;
  }
  @media screen and (min-width: ${wide}) {
    text-align: center;
    padding-top: 20px;
  }
  a:hover {
    text-decoration: none;
  }
`

const Tile = ({
  partner,
  partnerRoute,
  passcode,
  campaignRoute,
  thanksRoute,
  logo,
  hero,
}: Props) => {
  const navigate = useNavigate()
  const campaignHref = `/${globalPartnerRoute}/${partnerRoute}${campaignRoute}`

  return (
    <div className={cx(style)}>
      <div className={cx(imageStyle(hero))} onClick={() => navigate(campaignHref)}>
        <img src={logo || alintaLogo} alt={`${partner}'s logo`} />
      </div>

      <div className={cx(contentStyle)}>
        <Title size="small">{partner}</Title>
        {passcode && <CopyToClipboard>{passcode}</CopyToClipboard>}
        <Spacer height={8} />

        <div>
          <Inline>
            <Link to={campaignHref}>
              <GhostButton size="small">Access</GhostButton>
            </Link>
            <Link to={`/${globalPartnerRoute}/${partnerRoute}${thanksRoute}`}>
              <GhostButton size="small">Thanks</GhostButton>
            </Link>
          </Inline>
        </div>
      </div>
    </div>
  )
}

export default Tile
