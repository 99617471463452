import React from 'react'
import { cx, css } from 'emotion'
import { tablet, desktop } from '../variables.style'

type Props = {
  children?: React.ReactNode,
}

const style = css`
  padding-left: 24px;
  padding-right: 24px;
  @media screen and (min-width: ${tablet}) {
    padding-left: 64px;
    padding-right: 64px;
  }
  @media screen and (min-width: ${desktop}) {
    padding-left: 96px;
    padding-right: 96px;
  }
`

const ResponsiveAlignment = ({ children }: Props) => {
  return (
    <div className={cx(style)}>
      {children}
    </div>
  )
}

export default ResponsiveAlignment
