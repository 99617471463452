import { css, cx } from 'emotion'
import React from 'react'
import { Helmet } from 'react-helmet'
import Banner from './Banner'
import { fadein } from './PartnerPage'
import StopSaleSection from './StopSaleSection'
import logo from '../img/Alinta-Energy-Thatsbetter-landscape.jpg'
import hero from '../img/offline-page-hero.jpg'

type Props = {
  children?: React.ReactNode
  title?: string
  logo?: string
  dualBrandedLogo?: boolean
}

const style = css`
  animation-duration: 0.525s;
  animation-name: ${fadein};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;

  --thanks-page-bg: url(${hero});
`

const innerStyle = css`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const StoppedSalePage = ({ children }: Props) => {
  return (
    <div className={cx(style)}>
      <Helmet>
        <title>Offline</title>
      </Helmet>
      <Banner dualBrandedLogo={true}>
        <img src={logo} alt="Alinta Energy Logo" />
      </Banner>
      <div className={cx(innerStyle)}>
        <StopSaleSection title={'Alinta Energy is busy making things better for you'}>
          {children}
        </StopSaleSection>
      </div>
    </div>
  )
}

export default StoppedSalePage
