import { CampaignConfig } from '../../PartnerConfig'
import logo from './ellas-list.png'
import hero from './hero.jpg'

export const config: CampaignConfig = {
  partner: 'ellaslist',
  campaignName: 'EllasList',
  customerType: 'resi',
  partnerRoute: 'ellas-list',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  logo,
  hero,
  getSalesName: () => 'ELLASLIST',
  headlineText: 'Discover a better energy deal with Alinta Energy*',
  subHeadlineText: 'Enter your postcode to unlock savings today!',
}
