import { CloseButton, Markdown, Text } from '@cx/connect-ui'
import { whitespace, whitespaceRaw } from '@cx/connect-ui/lib/lib/styles'
import { css, cx } from 'emotion'
import React, { useState } from 'react'
import { desktop, tablet, wide } from 'variables.style'

type Props = {}

const style = css`
  position: relative;
  background: var(--pvebanner-blue);
  padding: ${whitespace[3]} ${whitespace[4]};
  color: white;
  text-align: center;
  min-height: 30px;

  .cui_text a {
    color: white;
    font-weight: bold;
    text-decoration: underline;

    &::after,
    &::hover {
      color: white;
    }
  }
`

const popupButtonStyle = css`
  border: 0;
  background: var(--pvebanner-blue);
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  margin-bottom: 0;
`
const closeButtonStyle = css`
  position: absolute;
  right: ${whitespace[3]};
  top: ${whitespaceRaw[3] + 60}px;
`

const innerCloseButtonStyle = css`
  height: 28px;
`

const bannerWrapper = css`
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-content: center;

  > div + div {
    margin-top: 0;
  }

  @media screen and (min-width: calc(${tablet} + 20px)) {
    flex-direction: column;

    > div + div {
      margin-top: 0;
    }
  }
  @media screen and (min-width: calc(${desktop} + 140px)) {
    flex-direction: row;
   
    > div + div {
      margin-left: ${whitespace[1]};
    }
  }
  @media screen and (min-width: ${wide}) {
  }
`

const bannerDetailWrapper = css`
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-content: center;

  > div + div {
    margin-top: 20px;
  }

  @media screen and (min-width: calc(${tablet} + 20px)) {
    flex-direction: column;

    > div + div {
      margin-top: 0;
    }
  }
  @media screen and (min-width: calc(${desktop} + 140px)) {
    flex-direction: column;
   
    > div + div {
      margin-left: ${whitespace[1]};
    }
  }
  @media screen and (min-width: ${wide}) {
  }
`

const subregion = css`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  > div + div {
    
  }

  @media screen and (min-width: calc(${tablet} + 100px)) {
    flex-direction: row;

    > div + div {
      margin-left: ${whitespace[2]};
      margin-top: 0;
    }
  }
  @media screen and (min-width: calc(${desktop} + 70px)) {
    flex-direction: row;
  }
  @media screen and (min-width: ${wide}) {
    flex-direction: row;
  }
`

const specialConditions = css`
  line-height: 24px;

  a {
    line-height: 24px;
  }

  @media screen and (min-width: calc(${desktop} + 140px)) {
    line-height: 30px;

    a {
      line-height: 30px;
    }
  }
`

const PveBanner = (_: Props) => {
  const [dismissed, setDismissed] = useState(false)
  const [showMoreInfo, setShowMoreInfo] = useState(false)

  // +10 is AEST
  const startDate1 = new Date('2023-05-01T00:00:00.000+10:00')
  const endDate1 = new Date('2023-06-19T08:59:59.000+10:00')
  const startDate2 = new Date('2023-06-19T09:00:00.000+10:00')
  const endDate2 = new Date('2023-06-30T23:59:59.000+10:00')
  const now = Date.now()

  const inRange1 = startDate1.getTime() <= now && now <= endDate1.getTime()
  const inRange2 = startDate2.getTime() <= now && now <= endDate2.getTime()
  const inRange = startDate1.getTime() <= now && now <= endDate2.getTime()
  const hideBanner = dismissed || !inRange

  if (hideBanner) {
    return null
  }

  return (
    <div className={cx(style)}>
      <div className={cx(bannerWrapper)}>
        <div className={cx(subregion)}>
          <Text size="large" className={cx(specialConditions)}>
            Please be aware that our prices are increasing.
          </Text>
        </div>
        <div className={cx(subregion)}>
          <Text block size="large" className={cx(specialConditions)}>
            <button
              className={cx(popupButtonStyle)}
              onClick={() => setShowMoreInfo(true)}
            >
              Click here for more information.​
            </button>
          </Text>
        </div>
      </div>
      {showMoreInfo && (
        <>
          <div className={cx(bannerDetailWrapper)}>
            <div className={cx(subregion)}>
              <Text block size="large" className={cx(specialConditions)}>
                <br></br>
                {inRange1 && (
                  <>
                    <Markdown>
                      Effective from 1 July 2023 Alinta Energy’s electricity & gas prices
                      will increase in New South Wales, Queensland and South Australia.
                      Our Victorian electricity and gas prices will increase on 1 August
                      2023.
                    </Markdown>

                    <Markdown>
                      New rates will be available to view from 19 June 2023.​
                    </Markdown>

                    <Markdown>
                      ​ We’ll have our new market offers available from __1 July 2023.__
                    </Markdown>
                  </>
                )}
                {inRange2 && (
                  <>
                    <Markdown>
                      Effective from 1 July 2023 Alinta Energy’s electricity & gas prices
                      will increase in New South Wales, Queensland and South Australia.
                      Our Victorian electricity and gas prices will increase on 1 August
                      2023.
                    </Markdown>

                    <Text block size="large" className={cx(specialConditions)}>
                      <a
                        title="Find out more"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.alintaenergy.com.au/pricechanges"
                      >
                        Click here to view
                      </a>{' '}
                      our new prices. ​
                    </Text>

                    <Markdown>
                      ​ We’ll have our new offers available from __1 July 2023.__
                    </Markdown>
                  </>
                )}
              </Text>
            </div>
          </div>
          <div className={cx(closeButtonStyle)}>
            <CloseButton
              className={cx(innerCloseButtonStyle)}
              color="white"
              onClick={() => setShowMoreInfo(false)}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default PveBanner
