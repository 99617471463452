import React from 'react'
import { cx, css } from 'emotion'

type Props = {
  children?: React.ReactNode,
  className?: string
  onClick: () => void
}

const style = css`
  position: absolute;
  background: rgba(255, 0, 0, 0.9);
  padding: 10px 100px;
  left: -100px;
  top: 30px;
  box-shadow: 0 0 20px rgba(158, 126, 126, 0.6);
  transform: rotate(-45deg);
  color: #fff;
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 10px;
  text-transform: uppercase;
  transition: transform 0.3s ease-out;
  cursor: pointer;
  &:hover {
    transform: rotate(-45deg) scale(1.1);
  }
`

const Ribbon = ({ children, className, onClick }: Props) => {
  return (
    <div className={cx(style)} onClick={onClick}>
      {children}
    </div>
  )
}

export default Ribbon
