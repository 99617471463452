import React from 'react'
import { cx, css } from 'emotion'
import alintaLogo from '../img/alinta-logo.png'
import cxLogo from '../img/cx-logo.png'
import CopyrightAndLinks from './CopyrightAndLinks'

type Props = {
  children?: React.ReactNode
  dualBrandedLogo?: boolean
  termsAndConditionsUrl?: string
}

const wrapperStyle = css`
  background-color: var(--footer-bg, var(--light-grey));
  text-align: center;
  padding-bottom: 16px;
`

const style = (bigLogo: boolean) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0 16px;

  .cxaf-footer--link {
    img {
      height: ${bigLogo ? '80px' : '40px'};
      margin-right: 16px;
    }
  }
  .cxaf-footer--link + .cxaf-footer--link {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 16px;
  }
`

const Footer = ({ children, termsAndConditionsUrl, dualBrandedLogo = false }: Props) => {
  return (
    <div className={cx(wrapperStyle)}>
      <div className={cx(style(dualBrandedLogo))}>
        {!dualBrandedLogo && (
          <div className="cxaf-footer--link">
            <a href="https://connectexpress.com.au/movers">
              <img src={cxLogo} alt="Connect Express logo" />
            </a>
          </div>
        )}
        <div className="cxaf-footer--link">{children}</div>
        {!dualBrandedLogo && (
          <div className="cxaf-footer--link">
            <a href="https://www.alintaenergy.com.au/">
              <img src={alintaLogo} alt="Alinta Energy logo" />
            </a>
          </div>
        )}
      </div>

      <CopyrightAndLinks termsAndConditionsUrl={termsAndConditionsUrl} />
    </div>
  )
}

export default Footer
