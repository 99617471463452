import { CampaignConfig } from '../../PartnerConfig'

import hero from '../template-resi/hero.jpg'

export const resiConfig: CampaignConfig = {
  partner: 'Moonee Resi',
  campaignName: 'Moonee Resi',
  customerType: 'resi',
  partnerRoute: 'moonee-resi',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  hero,
  logo: '',
  getSalesName: () => '01Moonee',
  headlineText: 'Check out this competitive energy deal for Moonee*',
  subHeadlineText: 'In partnership with Alinta Energy',
  termsText: '',
}
