import { CampaignConfig } from '../../PartnerConfig'
import hero from '../template/hero.jpg'

export const resiConfig: CampaignConfig = {
  partner: 'Kiosk Friends & Family (Resi)',
  campaignName: 'AE-Kiosk-FF-Resi',
  customerType: 'resi',
  partnerRoute: 'affinity-ff-resi',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  logo: '',
  hero: hero,
  getSalesName: () => 'CCCFandF',
  signupFormType: 'FullAddress',
  headlineText: 'Discover a better energy deal with Alinta Energy*',
  subHeadlineText: 'Enter your postcode to unlock savings today!',
  termsText: '',
  callToActionTextOverride: 'To see your offer, enter your address',
  showCompetitionBanner: true,
}
