import { getAuthResult } from 'access-control/cognito'
import { getConfig } from 'config'
import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import Loadable from '../../util/Loadable'
import PartnerConfig, { CampaignConfig } from '../PartnerConfig'
import hero from './hero.png'
import style from './style'

const appConfig = getConfig()
export const config: CampaignConfig = {
  partner: 'Kiosk (SME)',
  campaignName: 'AE-Kiosk-SME',
  customerType: 'sme',
  partnerRoute: 'ae-kiosk-sme-with-cognito',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  logo: '',
  hero: hero,
  accessControlType: 'cognito',
  getSalesName: () => getAuthResult().salesId,
  cannotOperateBefore: appConfig.cannotOperateBefore,
  cannotOperateAfter: appConfig.cannotOperateAfter,
  cognitoConfig: {
    ...appConfig.kioskCognito,
  },
}

// dynamic imports for code-splitting
export const Campaign = Loadable(lazy(() => import('./Campaign')))
export const Thanks = Loadable(lazy(() => import('./Thanks')))

const CampaignPages = () => {
  return (
    <PartnerConfig config={config} branding={style}>
      <Routes>
        <Route path={config.campaignRoute} element={<Campaign />} />
        <Route path={config.thanksRoute} element={<Thanks />} />
      </Routes>
    </PartnerConfig>
  )
}

export default CampaignPages
