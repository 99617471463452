import React from 'react'
import logo from './logo-lockup.png'
import CampaignFinishedPage from 'ui/CampaignFinishedPage'
import Markdown from '@cx/connect-ui/lib/components/Markdown'

const Finished = () => (
  <CampaignFinishedPage
    title="Sorry, this energy offer has now closed."
    logo={logo}
    dualBrandedLogo
  >
    <Markdown>
      The Cricket Australia Family & Friends Competition ended on 19 March 2023.
      Unfortunately, there were no Clubs that met the eligibility criteria set out in the
      Competition Terms & Conditions. Thank you to all the Clubs that entered!
    </Markdown>
  </CampaignFinishedPage>
)

export default Finished
