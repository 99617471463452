import React from 'react'
import { cx } from 'emotion'
import { Routes, Navigate } from 'react-router-dom'
import { AccessControlType } from 'access-control/AccessControl'
import _find from 'lodash/fp/find'
import md5 from 'md5'
import { getConfig } from 'config'
import { SignupFormType } from 'signup/SignupForm'
import { CognitoConfig, init as cognitoInit } from '../access-control/cognito'
import useAccessControl from 'access-control/useAccessControl'

export type CampaignConfig = {
  partner: string
  campaignName: string
  customerType: 'resi' | 'sme'
  partnerRoute: string
  passcode?: string
  campaignRoute: string
  thanksRoute: string
  campaignFinishedRoute: string
  logo: string
  hero: string
  getSalesName?: () => string
  headlineText?: string
  subHeadlineText?: string
  termsText?: string
  accessControlType?: AccessControlType
  cannotOperateBefore?: string
  cannotOperateAfter?: string
  signupFormType?: SignupFormType
  callToActionTextOverride?: string
  cognitoConfig?: CognitoConfig
  showCompetitionBanner?: boolean
  campaignRedundantLink?: string
}

type Props = {
  children?: React.ReactNode
  branding?: string
  config: CampaignConfig
}

export const CampaignContext = React.createContext({} as CampaignConfig)

const AccessControl = ({
  config,
  children,
}: {
  config: CampaignConfig
  children: React.ReactNode
}) => {
  const ready = useAccessControl({
    ...config,
    passcode: config.passcode ? md5(config.passcode) : undefined,
  })

  return <>{ready && children}</>
}

const PartnerConfig = ({ children, branding, config }: Props) => {
  const appConfig = getConfig()
  const cognitoRequired = config.accessControlType === 'cognito' && config.cognitoConfig
  if (cognitoRequired) {
    cognitoInit(config.cognitoConfig as CognitoConfig, config.partnerRoute)
  }

  const finishedCampaigns = appConfig.finishedCampaigns.concat(
    appConfig.temporaryClosedCampaigns
  )

  const campaignIsFinished = !!_find(
    (a) => a === config.partner.toLowerCase(),
    finishedCampaigns
  )

  return (
    <div className={cx(branding)}>
      <CampaignContext.Provider value={config}>
        <Routes>
          {campaignIsFinished ? (
            <Navigate
              to={`/partner/${config.partnerRoute}/${config.campaignFinishedRoute}`}
              replace={true}
            />
          ) : null}
        </Routes>
        <AccessControl config={config}>{children}</AccessControl>
      </CampaignContext.Provider>
    </div>
  )
}

export default PartnerConfig
