import { css } from 'emotion'
import hero from './hero.jpg'

export default css`
  --brand1: #28c2ad;
  --brand2: #171717;
  --brand3: #ebebeb;
  --brand4: #f7f7f7;
  --brand5: #0f45bb;
  --electricBlue: #196cff;
  // --flameOrange: #ff6100;
  // --electricDarkBlue: #004bcc;
  // --flameDarkOrange: #ef5b00;

  --headings: var(--brand1);
  --subheadings: var(--black);
  --icon: var(--brand1);
  --hero-bg: url(${hero});
  --features-bg: var(--brand4);
  --signup-bg: var(--electricBlue);
  --thanks-left-bg: var(--brand4);
  --thanks-page-bg: url(${hero});

  --button-primary: var(--brand1);
  --button-secondary: var(--brand5);
  --link: var(--brand1);
`
