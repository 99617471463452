import React from 'react'
import {
  Inline,
  Panel,
  PrimaryButton,
  Spacer,
  Stack,
  TextField,
  Title,
} from '@cx/connect-ui'
import md5 from 'md5'
import { cx, css } from 'emotion'
import { parse } from 'query-string'
import { Controller, useForm } from 'react-hook-form'
import { verifySalesId } from './promptForSalesIdIfRequired'
import { useNavigate } from 'react-router'

export const ACCESS_CODE_PARTIAL_KEY = 'cx-affinity-accesscode-'
export const SALES_ID_KEY = 'cx-affinity-sales-id'
export const SALES_ID_SIGNED_IN_AT_KEY = 'cx-affinity-sales-id-verified-at'

export const accessControlRoute = '/access-control'

export type AccessControlType = 'passcode' | 'salesId' | 'cognito'

type Props = {
  children?: React.ReactNode
}

const style = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #476070;
  background-color: #f3f5f6;
`

const salesIdTexts = {
  heading: 'Please login',
  inputLabel: 'Sales ID',
  requiredErrorMessage: 'Please enter the sales ID.',
  submitButton: 'Login',
}

const passcodeTexts = {
  heading: 'This page is private.',
  inputLabel: 'Access code',
  requiredErrorMessage: 'Please enter the access code.',
  submitButton: 'Unlock',
}

const AccessControl = ({ children }: Props) => {
  const { control, handleSubmit, errors, setError } = useForm()
  const codeErrors = Array.isArray(errors.code) ? errors.code[0] : errors.code
  const params = parse(window.location.search)
  const accessControlType = (params.type as AccessControlType) || 'passcode'
  const requestedPage = params.page as string
  const returnTo = params.returnTo as string
  const navigate = useNavigate()

  const onPasscodeSubmit = (data: any) => {
    const hashedPasscode = params.hash
    if (md5(data.code) === hashedPasscode) {
      localStorage.setItem(`${ACCESS_CODE_PARTIAL_KEY}${requestedPage}`, md5(data.code))
      navigate(returnTo, { replace: true })
    } else if (data.code !== hashedPasscode) {
      setError('code', 'notMatch', 'Code is not correct')
    }
  }

  const onSalesIdSubmit = (data: any) => {
    const salesId = data.code
    if (verifySalesId(salesId)) {
      localStorage.setItem(`${SALES_ID_KEY}`, salesId)
      localStorage.setItem(`${SALES_ID_SIGNED_IN_AT_KEY}`, new Date().toJSON())
      navigate(returnTo, { replace: true })
    } else {
      setError('code', 'notMatch', 'Sales Id is not correct')
    }
  }

  const texts = accessControlType === 'passcode' ? passcodeTexts : salesIdTexts
  const submit = accessControlType === 'passcode' ? onPasscodeSubmit : onSalesIdSubmit

  return (
    <div className={cx(style)}>
      <Stack>
        <Panel>
          <>
            <Title size="large">
              <span role="img" aria-label="padlock icon">
                🔒
              </span>{' '}
              {texts.heading}
            </Title>

            <Spacer height={32} />

            <form onSubmit={handleSubmit(submit)}>
              <Controller
                as={TextField}
                label={texts.inputLabel}
                placeholder="__  __  __  __  __  __  __  __  __  __"
                name="code"
                defaultValue=""
                rules={{ required: texts.requiredErrorMessage }}
                control={control}
                message={codeErrors && <span>{codeErrors.message}</span>}
                messageType={codeErrors && 'error'}
                autoFocus
                autoComplete="off"
              />

              <Spacer height={32} />
              <Inline>
                <PrimaryButton onClick={handleSubmit(submit)}>
                  {texts.submitButton}
                </PrimaryButton>
              </Inline>
            </form>
          </>
        </Panel>
      </Stack>
      <Spacer height={32} />
    </div>
  )
}

export default AccessControl
