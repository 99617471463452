import { CampaignConfig } from '../../PartnerConfig'
import logo from './strata-energy-services.png'
import hero from '../template/hero.jpg'

export const config: CampaignConfig = {
  partner: 'Strata Energy Services',
  campaignName: 'StrataEnergyServices',
  customerType: 'resi',
  partnerRoute: 'strata-energy-services',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  logo,
  hero,
  getSalesName: () => 'STRATACD',
  headlineText: 'Discover a better energy deal with Alinta Energy*',
  subHeadlineText: 'Enter your postcode to unlock savings today!',
}
