import { CampaignConfig } from '../../PartnerConfig'
import logo from './logo.png'
import hero from '../template/hero.jpg'

export const config: CampaignConfig = {
  partner: 'ICB',
  campaignName: 'icb',
  customerType: 'resi',
  partnerRoute: 'icb',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  logo,
  hero,
  getSalesName: () => 'ICBBUSCD',
  headlineText: 'Check out this great energy deal for ICB*',
  termsText: '',
  subHeadlineText: 'In partnership with Alinta Energy',
}
