/*
  The window.SETTINGS are injected via Azure and features can be dynamically toggled.
  Toggles will be false (undefined) by default on Test, Staging and Prod.
*/

export type FeatureToggles = {
  SETTINGS: { features: { [id: string]: boolean }; [id: string]: any }
}

type AppWindow = Window & typeof globalThis & FeatureToggles
export const appWindow = window as AppWindow

let features = {
  UPDATED_STATE_BASED_WORDING_JANUARY_01_2022: true,
  DISPLAY_SME_MOZO_AWARDS_SECTION: true,
  ALINTA_STOP_SALE: false,
}

if (appWindow.SETTINGS && appWindow.SETTINGS.features) {
  features = {
    ...features,
    ...appWindow.SETTINGS.features,
  }
}

export const UPDATED_STATE_BASED_WORDING_JANUARY_01_2022 = !!features.UPDATED_STATE_BASED_WORDING_JANUARY_01_2022
export const DISPLAY_SME_MOZO_AWARDS_SECTION = !!features.DISPLAY_SME_MOZO_AWARDS_SECTION
export const ALINTA_STOP_SALE =
  !!features.ALINTA_STOP_SALE && !document.cookie.includes('ALINTA_STOP_SALE_BYPASS=true')
