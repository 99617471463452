import React from 'react'
import { cx, css } from 'emotion'

const style = css`
  text-transform: uppercase;
  letter-spacing: 5px;
  background: #9eb1c9;
  width: 100%;
  padding: 12px 0 10px 0;
  color: #fff;
  font-size: 11px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  font-weight: 400;
  text-align: center;
`

const Heading = () => {
  return (
    <div className={cx(style)}>
      <span>Affinity Partners</span>
    </div>
  )
}

export default Heading
