import { css } from 'emotion'
import hero from './hero.jpg'
import features from './features-bg.jpg'

export default css`
  --brand1: 247, 202, 204;
  --brand2: rgb(201, 115, 126);
  --brand3: rgb(224, 115, 102);
  --brand4: rgb(26, 46, 88);

  --headings: var(--brand4);
  --subheadings: var(--brand4);
  --icon: var(--brand4);
  --form-icon: var(--brand3);
  --placeholder-text: rgb(var(--brand1));
  --hero-bg: url(${hero});
  --features-bg: linear-gradient(rgba(var(--brand1), 0.6), rgba(var(--brand1), 0.6)),
    url(${features});
  --signup-bg: linear-gradient(180deg, var(--brand2) 5%, var(--brand3) 95%);
  --thanks-left-bg: white;
  --thanks-page-bg: url(${hero});

  --button-primary: var(--brand3);
  --button-secondary: var(--brand4);
  --link: var(--brand3);
`
