import rollbar from './rollbar'

type CustomError = {
  customMessage?: string
  message?: string
}

function handleError(e: CustomError) {
  let message = e.customMessage ? e.customMessage : `${e.message}`
  rollbar.error(message, e)
  console.error('reported', message)
}

export default handleError
