import React, { Suspense } from 'react'
import { Loader } from '@cx/connect-ui'

const Loadable = (Component: any) => (props: any) => (
  <Suspense fallback={<Loader size="small" />}>
    <Component {...props} />
  </Suspense>
)

export default Loadable
