import { CampaignConfig } from '../../PartnerConfig'
import logo from './logo.png'
import hero from '../template/hero.jpg'

export const config: CampaignConfig = {
  partner: 'Energy Alliance',
  campaignName: 'Energy Alliance',
  customerType: 'resi',
  partnerRoute: 'energy-alliance',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  logo,
  hero,
  getSalesName: () => 'TEACD',
  headlineText: 'Check out this great energy deal for Energy Alliance*',
  subHeadlineText: 'In partnership with Alinta Energy',
}
