import React from 'react'
import logo from '../img/logo-alinta-wide.png'
import { Spacer, Stack} from '@cx/connect-ui'
import NotFoundContentPage from './NotFoundContentPage'
import { cx, css } from 'emotion'

const style = css`
    display: flex;
    over-flow: hidden;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`

const NotFoundPage = () => {
    return (
      <div className={cx(style)}>
        <Stack align="center">
            <Spacer height={16} />
            {logo && <img src={logo} alt="Alinto logo" /> }
            <Spacer height={16} />
        </Stack>
        <NotFoundContentPage />
      </div>
    )
  }

  export default  NotFoundPage
