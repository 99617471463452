import { CampaignConfig } from '../../PartnerConfig'
import hero from '../template-sme-nsw-qld/hero.jpg'

export const smeNswQldConfig: CampaignConfig = {
  partner: 'Moonee SME NSW QLD',
  campaignName: 'Moonee SME NSW QLD',
  customerType: 'sme',
  partnerRoute: 'moonee-sme-nsw-qld',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  hero,
  logo: '',
  getSalesName: () => '01Moonee',
  headlineText:
    'Check out this invitation-only energy offer* for your business, thanks to Alinta Energy',
  subHeadlineText: 'Discover a great energy deal with Alinta Energy',
  termsText: '',
}
