import React from 'react'
import { cx, css } from 'emotion'
import { NavLink } from 'react-router-dom'
import { InlineSvg, Stack } from '@cx/connect-ui'
import { tablet } from '../variables.style'

type Props = {
  to: string
  label: string
  icon: string
  count: number
}

const grey = '#576a81'

const style = css`
  font-size: 11px;
  color: ${grey};
  font-weight: 300;
  min-width: 70px;
  a {
    color: ${grey};
  }
  > div {
    opacity: 0.5;
  }
  &:hover {
    text-decoration: none;
    > div {
      opacity: 1;
    }
  }
  .cui_inline-svg svg {
    height: 32px;
    width: 32px;
    @media screen and (min-width: ${tablet}) {
      height: 64px;
      width: 64px;
    }
    path {
      fill: ${grey};
    }
  }
  .cxaf_icon-tab--count {
    display: inline-block;
    align-self: center;
  }
`

const labelStyle = css`
  text-transform: uppercase;
  letter-spacing: 1px;
`

const activeStyle = css`
  > div {
    opacity: 1;
    font-weight: 700;
  }
  .cxaf_icon-tab--count {
    color: white;
    background: ${grey};
    border-radius: 50px;
    padding: 1px 12px;
  }
`

const IconTab = ({ to, label, icon, count }: Props) => {
  return (
    <NavLink to={to} className={cx(style)} activeClassName={cx(activeStyle)}>
      <Stack>
        <InlineSvg src={icon} alt={label} />
        <div className={cx(labelStyle)}>{label}</div>
        <div className="cxaf_icon-tab--count">{count}</div>
      </Stack>
    </NavLink>
  )
}

export default IconTab
