import { CampaignConfig } from '../../PartnerConfig'
import logo from './logo.png'
import hero from '../template-sme/hero.jpg'

export const smeConfig: CampaignConfig = {
  partner: 'Utility Hub (SME)',
  campaignName: 'Utility Hub-SME',
  customerType: 'sme',
  partnerRoute: 'utility-hub-sme',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-redundant',
  logo,
  hero,
  getSalesName: () => 'UHDigi',
  headlineText: 'Looks like you\'re using an old link',
  termsText: '',
  campaignRedundantLink: 'https://www.alintaenergy.com.au/residential/electricity-and-gas/products/campaigns/utility-hub'
}
