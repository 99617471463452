import { CampaignConfig } from '../partners/PartnerConfig'
import { config as cricketAustralia } from '../partners/cricket-australia/Index'
import { config as easi } from '../partners/easi/Index'
import { config as uniti } from '../partners/uniti/Index'
import { config as unitiNsw } from '../partners/uniti-nsw/Index'
import { config as unitiQld } from '../partners/uniti-qld/Index'
import { config as unitiVic } from '../partners/uniti-vic/Index'
import { config as unitiSa } from '../partners/uniti-sa/Index'
import { config as qendo } from '../partners/qendo/Index'
import { config as connectAssist } from '../partners/connect-assist/Index'
import { smeConfig as tamhaSme } from '../partners/tamha/Index'
import { resiConfig as tamhaResi } from '../partners/tamha/Index'
import { smeConfig as easiSme } from '../partners/easi-sme/Index'
import { config as icbBodyCorp } from '../partners/icb-body-corp/Index'
import { config as alintaKiosk } from '../partners/alinta-kiosk/Index'
import { config as alintaKioskSme } from '../partners/alinta-kiosk-sme/Index'
import { config as anz } from '../partners/anz/Index'
import { config as qldCricketResiConfig } from '../partners/qld-cricket/Index'
import { config as gippslandResiConfig } from '../partners/gippsland-resi/Index'
import { config as gippslandSmeConfig } from '../partners/gippsland-sme/Index'
import { config as esgCarbonResiConfig } from '../partners/esg/Index'
import { config as esgCarbonSmeConfig } from '../partners/esg-sme/Index'

// Brokers Start
import { config as icb } from '../partners/brokers/icb/config'
import { config as bulkEnergy } from '../partners/brokers/bulk-energy/config'
import { config as dolphinEnergy } from '../partners/brokers/dolphin-energy/config'
import { config as choiceEnergy } from '../partners/brokers/choice-energy/config'
import { config as energyIntelligence } from '../partners/brokers/energy-intelligence/config'
import { config as forNrg } from '../partners/brokers/fornrg/config'
import { config as leadingEdgeEnergy } from '../partners/brokers/leading-edge-energy/config'
import { config as enerCom } from '../partners/brokers/enercom/config'
import { config as ripperDeals } from '../partners/brokers/ripper-deals/config'
import { config as specialistEnergyMan } from '../partners/brokers/specialist-energy-management/config'
import { config as susEnergySol } from '../partners/brokers/sustainable-energy-solutions/config'
import { config as strataEnergySol } from '../partners/brokers/strata-energy-services/config'
import { config as hellenicPower } from '../partners/brokers/hellenic-power/config'
import { config as ems } from '../partners/brokers/ems/config'
import { config as ellasList } from '../partners/brokers/ellas-list/config'
import { resiConfig as utilityHubResi } from '../partners/brokers/utility-hub/resiConfig'
import { smeConfig as utilityHubSme } from '../partners/brokers/utility-hub/smeConfig'
import { resiConfig as edgeUtilitiesResi } from '../partners/brokers/edge-utilities/resiConfig'
import { resiConfig as beevoResi } from '../partners/brokers/beevo/resiConfig'

import { config as energyAlliance } from '../partners/brokers/energy-alliance/config'
import { resiConfig as kioskFriendsAndFamilyResiConfig } from '../partners/brokers/kiosk-friends-and-family/resiConfig'
import { smeConfig as kioskFriendsAndFamilySmeConfig } from '../partners/brokers/kiosk-friends-and-family/smeConfig'
import { smeConfig as qldCricketSmeConfig } from '../partners/brokers/qld-cricket/smeConfig'
import { smeConfig as qantasConfig } from '../partners/qantas/Index'
// import { resiConfig as esgResi } from '../partners/brokers/esg-foundation/resiConfig'
// import { smeConfig as esgSme } from '../partners/brokers/esg-foundation/smeConfig'

// Brokers End

// Generic Affinity
import { resiConfig as mooneeResiConfig } from '../partners/generic-alinta/moonee/resiConfig'
import { smeNswQldConfig as mooneeSmeeNswQldConfig } from '../partners/generic-alinta/moonee/smeNswQldConfig'
import { smeSaVicConfig as mooneeSmeSaVicConfig } from '../partners/generic-alinta/moonee/smeSaVicConfig'
import { config as apodConfig } from '../partners/generic-alinta/apod/config'

const pending: CampaignConfig[] = []

const future: CampaignConfig[] = [edgeUtilitiesResi]

const approved = [
  connectAssist,
  anz,
  utilityHubResi,
  utilityHubSme,
  beevoResi,
  gippslandSmeConfig,
  gippslandResiConfig,
  mooneeResiConfig,
  esgCarbonResiConfig,
  esgCarbonSmeConfig,
  // esgResi,
  // esgSme,
]

const onhold: CampaignConfig[] = [
  easi,
  easiSme,
  forNrg,
  qendo,
  ellasList,
  icbBodyCorp,
  qldCricketResiConfig,
  qldCricketSmeConfig,
  susEnergySol,
  strataEnergySol,
  hellenicPower,
  ems,
  alintaKiosk,
  alintaKioskSme,
  kioskFriendsAndFamilyResiConfig,
  kioskFriendsAndFamilySmeConfig,
]

const closed: CampaignConfig[] = [
  tamhaResi,
  tamhaSme,
  uniti,
  unitiNsw,
  unitiQld,
  unitiSa,
  unitiVic,
  qantasConfig,
  cricketAustralia,
  icb,
  bulkEnergy,
  dolphinEnergy,
  choiceEnergy,
  enerCom,
  energyAlliance,
  energyIntelligence,
  leadingEdgeEnergy,
  ripperDeals,
  specialistEnergyMan,
  mooneeSmeeNswQldConfig,
  mooneeSmeSaVicConfig,
  apodConfig
]

const parters = {
  pending,
  future,
  approved,
  onhold,
  closed,
}

export default parters
