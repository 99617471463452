import React, { useMemo } from 'react'
import { cx, css, keyframes } from 'emotion'
import Banner from './Banner'
import Footer from './Footer'
import { getConfig } from '../config'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import CompetitionBanner from './CompetitionBanner'
import { BannerButton } from './Banner'
import PveBanner from './PveBanner'

type Props = {
  children?: React.ReactNode
  className?: string
  logo?: string
  footerLogo?: string
  conceptOnly?: boolean
  dualBrandedLogo?: boolean
  belowFooter?: React.ReactNode
  showCompetitionBanner?: boolean
  bannerButtons?: BannerButton[]
}

export const fadein = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const style = css`
  animation-duration: 0.425s;
  animation-name: ${fadein};
  animation-timing-function: ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  a {
    color: var(--link, var(--black));
  }
`

const innerStyle = css`
  flex: 1;
  flex-direction: column;
`

const PartnerPage = ({
  children,
  className,
  conceptOnly = false,
  logo,
  footerLogo,
  dualBrandedLogo,
  belowFooter,
  showCompetitionBanner = false,
  bannerButtons,
}: Props) => {
  const config = useMemo(() => getConfig(), [])
  const logoForFooter = footerLogo ? footerLogo : logo
  return (
    <div className={cx(className, style)}>
      <GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaSiteKey}>
        {showCompetitionBanner && <CompetitionBanner />}
        <PveBanner />
        <Banner
          conceptOnly={conceptOnly}
          dualBrandedLogo={dualBrandedLogo}
          buttons={bannerButtons}
        >
          {logo && <img src={logo} alt="Partner Logo" />}
        </Banner>
        <div className={cx(innerStyle)}>{children}</div>
        <Footer dualBrandedLogo={dualBrandedLogo}>
          {logoForFooter && <img src={logoForFooter} alt="Partner Logo" />}
        </Footer>
        {belowFooter}
      </GoogleReCaptchaProvider>
    </div>
  )
}

export default PartnerPage
