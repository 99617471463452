import React from 'react'
import { Panel, Title } from '@cx/connect-ui'
import handleError from './handleError.ts'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })
    console.log(`Additional error info: ${info}`)
    handleError(error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <Title size="large"><strong>Oops...</strong></Title>
          <Panel>
            <>
              <Title size="small">Sorry, something went wrong :(</Title>
              <Title size="small">Please try refreshing this page.</Title>
            </>
          </Panel>
        </div>
      )
    }

    return this.props.children
  }
}
