import React from 'react'
import logo from './logo.png'
import CampaignFinishedPage from 'ui/CampaignFinishedPage'

const Finished = () => (
  <CampaignFinishedPage
    title="Sorry, this QENDO & Alinta Energy campaign has now finished."
    logo={logo}
  >
    You can help QENDO as they continue to support anyone affected by endometriosis,
    adenomyosis, PCOS, infertility or pelvic pain by visiting <a href="qendo.org.au">qendo.org.au</a>.
  </CampaignFinishedPage>
)

export default Finished
