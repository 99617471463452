import React from 'react'
import { cx } from 'emotion'
import { Link } from 'react-router-dom'
import { SecondaryButton } from '@cx/connect-ui'
import alintaLogo from '../img/logo-alinta-wide.png'
import ResponsiveAlignment from './ResponsiveAlignment'
import Ribbon from './Ribbon'
import * as styles from './Banner.style'

export type BannerButton = {
  buttonLabel: string
  buttonDestination: string
}

type Props = {
  children?: React.ReactNode
  conceptOnly?: boolean
  dualBrandedLogo?: boolean
  buttons?: BannerButton[]
}

const Banner = ({
  children,
  conceptOnly = false,
  dualBrandedLogo = false,
  buttons,
}: Props) => {
  return (
    <div className={cx(styles.wrapper)}>
      {conceptOnly && (
        <Ribbon
          onClick={() => {
            window.location.href = `${window.location.origin}/link/testing.html`
          }}
        >
          Concept Only
        </Ribbon>
      )}

      <ResponsiveAlignment>
        <div className={cx(styles.main)}>
          {!dualBrandedLogo && (
            <div className={cx(styles.alinta(!!children))}>
              <img src={alintaLogo} alt="Alinta Energy logo" />
            </div>
          )}
          {children && (
            <div className={cx(styles.child(dualBrandedLogo))}>{children}</div>
          )}
        </div>
      </ResponsiveAlignment>

      {buttons && buttons.length > 0 && (
        <div className={cx(styles.multiButtonsWrapper)}>
          {buttons.map((e, i) => (
            <div key={i}>
              <div className={cx(styles.buttonWrapper)}>
                <div className={cx(styles.button.narrow)}>
                  <Link to={e.buttonDestination}>{e.buttonLabel}</Link>
                </div>
              </div>
              <div className={cx(styles.multiButtonsWide)}>
                <Link to={e.buttonDestination}>
                  <SecondaryButton color="secondaryBlue" size="small">
                    {e.buttonLabel}
                  </SecondaryButton>
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Banner
