import React from 'react'
import errorLogo from './sad-laptop-error.png'
import { Text, Title, Stack, Spacer} from '@cx/connect-ui'
import { cx, css } from 'emotion'

const style = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    background-color: #d8d8d8;
`
const NotFoundContent = () => {
  return(
    <div className={cx(style)}>
      <Stack align="center">
        {errorLogo && <img src={errorLogo} alt="Error logo" height="64" width="80" />}
        <Spacer height={8} />

        <Title size="large">Uh-oh, we can't seem to find that.</Title>
        <Spacer height={8} />

        <Text>Sorry but we're having trouble finding the page you wanted.</Text>
        <Text>Perhaps go back and check the link you followed.</Text>
      </Stack>
    </div>
  )
}

export default NotFoundContent
