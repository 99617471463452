import React from 'react'
import { cx, css } from 'emotion'

const style = css`
  border-style: solid;
  border-width: 0.15em 0.15em 0 0;
  content: '';
  display: inline-block;
  height: 0.7em;
  left: 0.35em;
  position: relative;
  top: 0.29em;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.7em;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
`

export default () => <div className={cx(style)} />
