import { CampaignConfig } from '../../PartnerConfig'
import logo from './logo.png'
import hero from './hero.jpg'

export const smeConfig: CampaignConfig = {
  partner: 'Qld Cricket (SME)',
  campaignName: 'Queensland Cricket SME',
  customerType: 'sme',
  partnerRoute: 'qld-cricket-sme',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  logo,
  hero,
  getSalesName: () => 'QLDCRICKET',
  headlineText:  'Discover a better energy deal with Alinta Energy',
  subHeadlineText: 'Enter your postcode to unlock savings today!',
}
