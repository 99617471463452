import React from 'react'
import { cx, css } from 'emotion'
import { Spacer, Text, Title } from '@cx/connect-ui'
import { small, responsive, tiny } from '@cx/connect-ui/lib/lib/responsive'
import CopyrightAndLinks from './CopyrightAndLinks'
import ResponsiveAlignment from './ResponsiveAlignment'
import { tablet, desktop, wide, defaultPrimaryButtonColor } from '../variables.style'
import LinkButton from './LinkButton'
import RightChevron from './RightChevron'

const style = css`
  text-align: center;
  flex: 1;
  background: var(--thanks-page-bg);
  background-size: cover;
  background-position-x: var(--thanks-bg-offset, 50%);

  @media screen and (min-width: ${tablet}) {
    text-align: left;
  }
  @media screen and (min-width: ${desktop}) {
    display: flex;
  }
`

const leftStyle = css`
  display: flex;
  align-items: center;
  flex: 1;
  background-color: var(--thanks-left-bg, var(--light-grey));
  padding-top: 64px;
  padding-bottom: 64px;
  > div {
    padding-right: 36px;
    @media screen and (min-width: ${wide}) {
      padding-right: 108px;
    }
  }
  @media screen and (min-width: ${tablet}) {
    min-height: calc(100vh - 80px);
  }
`
const rightStyle = css`
  flex: 1;
`

const titleStyle = css`
  white-space: pre-line;
`

export const linkStyle = css`
  --button-secondary: ${defaultPrimaryButtonColor};
  >.cui_primary-button {
    font-weight: normal;
    min-width: auto;
    width: 220px;
    @media screen and (min-width: ${tablet}) {
      width: 220px;
    }
  }
`

type Props = {
  children?: React.ReactNode
  title?: string
}

const CampaignFinishedSection = ({
  children,
  title = '',
}: Props) => {
  return (
    <div className={cx(style)}>
      <div className={cx(leftStyle)}>
        <ResponsiveAlignment>
          <Spacer height={8} />
          <Title className={cx(titleStyle)} size={responsive(tiny('large'), small('giant'))}>{title}</Title>
          <Spacer height={16} />
          {children}
          <Spacer height={32} />
          <div>
            <Text>
              <strong>Still looking for a great energy deal from Alinta Energy?</strong>
              <Spacer height={32} />
              <LinkButton className={cx(linkStyle)} color="primaryBlue" href="https://alintaenergy.com.au" renderRight={() => <RightChevron />}>
                Find an energy deal
              </LinkButton>
            </Text>
          </div>
          <Spacer height={32} />
          <CopyrightAndLinks />
        </ResponsiveAlignment>
      </div>

      <div className={cx(rightStyle)}></div>
    </div>
  )
}

export default CampaignFinishedSection
