import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import Loadable from '../../../util/Loadable'
import PartnerConfig from '../../PartnerConfig'
import brandStyle from './style'
import { CampaignConfig } from '../../PartnerConfig'

// dynamic imports for code-splitting
export const Campaign = Loadable(lazy(() => import('./Campaign')))
export const Thanks = Loadable(lazy(() => import('./Thanks')))
// export const Finished = Loadable(lazy(() => import('./Finished')))
export const Finished = Loadable(lazy(() => import('../../TemporaryClosed')))

type Props = {
  config: CampaignConfig
  resiRoute?: string
  smeNswQldRoute?: string
}

const CampaignPages = ({ config, resiRoute, smeNswQldRoute }: Props) => (
  <PartnerConfig config={config} branding={brandStyle(config.hero)}>
    <Routes>
      <Route
        path={config.campaignRoute}
        element={
          <Campaign
            config={config}
            resiRoute={resiRoute}
            smeNswQldRoute={smeNswQldRoute}
          />
        }
      />
      <Route
        path={config.thanksRoute}
        element={<Thanks config={config} resiRoute={resiRoute} />}
      />
      <Route path={config.campaignFinishedRoute} element={<Finished config={config} />} />
    </Routes>
  </PartnerConfig>
)

export default CampaignPages
