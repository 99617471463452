import { CampaignConfig } from '../../PartnerConfig'
import logo from './bulk-energy.png'
import hero from '../template/hero.jpg'

export const config: CampaignConfig = {
  partner: 'Bulk Energy',
  campaignName: 'BulkEnergy',
  customerType: 'resi',
  partnerRoute: 'bulk-energy',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  logo,
  hero,
  getSalesName: () => 'BULKENCD',
  headlineText: 'Check out this great energy deal for Bulk Energy*',
  subHeadlineText: 'In partnership with Alinta Energy',
}
