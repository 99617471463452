import { css } from 'emotion'

const style = (hero: string) => {
  return css`
  --brand1: #f26722;
  --brand2: #3279c7;
  --brand3: #3e3c3b;
  --brand4: #f4f4f5;
  --electricBlue: #196cff;

  --headings: var(--brand3);
  --subheadings: var(--black);
  --icon: var(--brand1);
  --form-icon: var(--brand1);
  --placeholder-text: var(--brand3);
  --hero-bg: url(${hero});
  --features-bg: var(--brand4);
  --feature-heading: var(--brand3);
  --signup-bg: var(--electricBlue);
  --thanks-left-bg: white;
  --thanks-bg-offset: 70%;
  --thanks-page-bg: url(${hero});
  
  --button-primary: var(--brand1);
  --button-secondary: var(--brand2);
  --link: var(--brand5);
  /* TODO - cleanup
  #signup .cui_primary-button {
    background-color: var(--button-primary);
    &:hover {
      background-color: var(--button-primary);
    }
    &:active,
    &:focus {
      background-color: var(--button-primary);
    }
  }
  */

  `
}

export default style
export const businessLink = css`
  color: #196CFF !important;
  text-decoration: underline;
  font-weight: lighter;
`
export const frequentlyAskQuestion = css`
font-family: Arial,Poppins,Tahoma,sans-serif;
text-align: center;
`
