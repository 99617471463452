import React from 'react'
import { cx, css } from 'emotion'
import { Helmet } from 'react-helmet'
import Banner from './Banner'
import { fadein } from './PartnerPage'
import CampaignFinishedSection from './CampaignFinishedSection'

type Props = {
  children?: React.ReactNode
  title?: string
  logo?: string
  dualBrandedLogo?: boolean
}

const style = css`
  animation-duration: 0.525s;
  animation-name: ${fadein};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
`

const innerStyle = css`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const CampaignFinishedPage = ({
  children,
  logo,
  title,
  dualBrandedLogo,
}: Props) => {
  return (
    <div className={cx(style)}>
      <Helmet>
        <title>Campaign Completed</title>
      </Helmet>
      <Banner dualBrandedLogo={dualBrandedLogo}>
        {logo && <img src={logo} alt="Partner Logo" />}
      </Banner>
      <div className={cx(innerStyle)}>
        <CampaignFinishedSection title={title}>
          {children}
        </CampaignFinishedSection>
      </div>
    </div>
  )
}

export default CampaignFinishedPage
