import { CloseButton, InlineSvg, Text } from '@cx/connect-ui'
import { whitespace, whitespaceRaw } from '@cx/connect-ui/lib/lib/styles'
import { css, cx } from 'emotion'
import React, { useState } from 'react'
import { desktop, tablet, wide } from 'variables.style'
import offerIcon from '../img/icons/cash-reward.svg'

type Props = {}

const style = css`
  position: relative;
  background: var(--alinta-orange);
  padding: ${whitespace[3]} ${whitespace[4]};
  color: white;
  text-align: center;
  min-height: 30px;

  .cui_text a {
    color: white;
    font-weight: bold;
    text-decoration: underline;

    &::after,
    &::hover {
      color: white;
    }
  }
`

const iconStyle = css`
  position: relative;
  top: -2px;

  height: 24px;
  margin-bottom: 6px;

  @media screen and (min-width: calc(${tablet} + 100px)) {
    height: 18px;
    top: -1px;
    margin-bottom: 0;
    margin-right: ${whitespace[3]};

    overflow: visible;
  }

  @media screen and (min-width: calc(${desktop} + 140px)) {
    top: 0;
  }

`

const closeButtonStyle = css`
  position: absolute;
  right: ${whitespace[3]};
  top: ${whitespaceRaw[3] + 3}px;
`

const innerCloseButtonStyle = css`
  height: 28px;
`

const bannerWrapper = css`
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-content: center;

  > div + div {
    margin-top: 0;
  }

  @media screen and (min-width: calc(${tablet} + 20px)) {
    flex-direction: column;

    > div + div {
      margin-top: 0;
    }
  }
  @media screen and (min-width: calc(${desktop} + 140px)) {
    flex-direction: row;
   
    > div + div {
      margin-left: ${whitespace[1]};
    }
  }
  @media screen and (min-width: ${wide}) {
  }
`

const subregion = css`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  > div + div {
    
  }

  @media screen and (min-width: calc(${tablet} + 100px)) {
    flex-direction: row;

    > div + div {
      margin-left: ${whitespace[2]};
      margin-top: 0;
    }
  }
  @media screen and (min-width: calc(${desktop} + 70px)) {
    flex-direction: row;
  }
  @media screen and (min-width: ${wide}) {
    flex-direction: row;
  }
`

const specialConditions = css`
  line-height: 24px;

  a {
    line-height: 24px;
  }

  @media screen and (min-width: calc(${desktop} + 140px)) {
    line-height: 30px;

    a {
      line-height: 30px;
    }
  }
`

const CompetitionBanner = (_: Props) => {
  const [dismissed, setDismissed] = useState(false)

  // +10 is AEST, "The Competition for Uniti commences at 9:00 (AEST) on 2 March 2022  and closes at 5:00PM (AEST) on 1 June 2022 (Competition Period)"
  // Marketing confirmed that the banner will go live on 2nd March.
  const startDate = new Date('2022-03-02T09:00:00.000+10:00')
  const endDate = new Date('2022-06-01T17:00:00.000+10:00')
  const now = Date.now()

  const inRange = startDate.getTime() <= now && now <= endDate.getTime()

  const hideBanner = dismissed || !inRange

  if (hideBanner) {
    return null
  }

  return (
    <div className={cx(style)}>
      <div className={cx(bannerWrapper)}>
        <div className={cx(subregion)}>
          <InlineSvg
            src={offerIcon}
            alt="An icon of a dollar bill symbolising a monetary prize"
            className={cx(iconStyle)}
          />
          <Text size="large" className={cx(specialConditions)}>
            Win up to a year’s worth of electricity!  Sign up to enter. T&amp;Cs
            apply.
          </Text>
        </div>
        <div className={cx(subregion)}>
          <Text block size="large" className={cx(specialConditions)}>
            <a
              title="Find out more"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.alintaenergy.com.au/uniticompetition"
            >
              Find out more
            </a>
          </Text>
        </div>
      </div>
      <div className={cx(closeButtonStyle)}>
        <CloseButton
          className={cx(innerCloseButtonStyle)}
          color="white"
          onClick={() => setDismissed(true)}
        />
      </div>
    </div>
  )
}

export default CompetitionBanner
