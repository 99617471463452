import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import Loadable from '../../util/Loadable'
import PartnerConfig from '../PartnerConfig'
import logo from './logo-square.png'
import smeHero from './hero-sme.jpg'
import resiHero from './hero-resi.jpg'
import { CampaignConfig } from '../PartnerConfig'
import resiStyle from './resi.style'
import smeStyle from './sme.style'

export const smeConfig: CampaignConfig = {
  partner: 'TAMHA (SME)',
  campaignName: 'TAMHA-SME',
  customerType: 'sme',
  partnerRoute: 'tamha/sme',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  logo,
  hero: smeHero,
}

export const resiConfig: CampaignConfig = {
  partner: 'TAMHA (Resi)',
  campaignName: 'TAMHA-RESI',
  customerType: 'resi',
  partnerRoute: 'tamha/resi',
  campaignRoute: '/',
  thanksRoute: '/thanks',
  campaignFinishedRoute: '/campaign-finished',
  logo,
  hero: resiHero,
}

// dynamic imports for code-splitting
export const ResiCampaign = Loadable(lazy(() => import('./ResiCampaign')))
export const ResiThanks = Loadable(lazy(() => import('./ResiThanks')))
export const SmeCampaign = Loadable(lazy(() => import('./SmeCampaign')))
export const SmeThanks = Loadable(lazy(() => import('./SmeThanks')))
export const Finished = Loadable(lazy(() => import('../TemporaryClosed')))

export const ResiCampaignPages = () => (
  <PartnerConfig config={resiConfig} branding={resiStyle(resiConfig.hero)}>
    <Routes>
      <Route path={resiConfig.campaignRoute} element={<ResiCampaign />} />
      <Route path={resiConfig.thanksRoute} element={<ResiThanks />} />
      <Route
        path={resiConfig.campaignFinishedRoute}
        element={<Finished config={resiConfig} />}
      />
    </Routes>
  </PartnerConfig>
)

export const SmeCampaignPages = () => (
  <PartnerConfig config={smeConfig} branding={smeStyle(smeConfig.hero)}>
    <Routes>
      <Route path={smeConfig.campaignRoute} element={<SmeCampaign />} />
      <Route path={smeConfig.thanksRoute} element={<SmeThanks />} />
      <Route
        path={smeConfig.campaignFinishedRoute}
        element={<Finished config={smeConfig} />}
      />
    </Routes>
  </PartnerConfig>
)
