import { css } from 'emotion'
import { tablet, desktop } from '../variables.style'

export const wrapper = css`
  position: relative; // required to make shadow appear "on top of" siblings
  background-color: white;
  padding-top: 10px;
  @media screen and (min-width: ${desktop}) {
    padding-bottom: 4px;
  }
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  clip-path: inset(0px 1px -20px 1px);
  overflow: visible;
`

export const main = css`
  display: flex;
  align-items: center;
  justify-content: left;
`

export const child = (dualBranding: boolean) => css`
  flex: 1;
  border-left: ${dualBranding ? 'none' : '1px solid #ddd'};
  padding-left: ${dualBranding ? '0' : '16px'};
  img {
    height: ${dualBranding ? '80px' : '40px'};
  }
`

export const alinta = (hasChildren: boolean) => css`
  flex: 1;
  text-align: ${hasChildren ? 'right' : 'center'};
  margin-right: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  @media screen and (min-width: ${tablet}) {
    flex: 0;
    text-align: left;
  }
  img {
    height: 40px;
  }
`

export const buttonWrapper = css`
  height: 50px;
  background: var(--banner-strip-bg, var(--black));
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: ${desktop}) {
    height: auto;
    background: transparent;
  }
  && a {
    color: white;
    font-size: 12px;
  }
`

export const button = {
  narrow: css`
    @media screen and (min-width: ${desktop}) {
      display: none;
    }
  `,
  wide: css`
    display: none;
    a:hover {
      text-decoration: none;
    }
    @media screen and (min-width: ${desktop}) {
      display: block;
      position: absolute;
      top: 24px;
      right: 24px;
    }
  `,
}

export const multiButtonsWrapper = css`
    a:hover {
      text-decoration: none;
    }
    @media screen and (min-width: ${desktop}) {
      display: flex;
      position: absolute;
      top: 24px;
      right: 24px;
    }
  `

export const multiButtonsWide = css`
  display: none;
  a:hover {
    text-decoration: none;
  }
  @media screen and (min-width: ${desktop}) {
    display: flex;
    margin: 0.2em;
  }
`