import React from 'react'
import { cx, css } from 'emotion'
import { tablet, desktop, wide } from '../variables.style'
import Tile, { Props as partnerConfig } from './Tile'

type Props = {
  items: partnerConfig[]
}

const style = css`
  display: grid;
  justify-content: center;
  grid-template-columns: auto;
  grid-gap: 16px;
  @media screen and (min-width: ${tablet}) {
    grid-template-columns: 80%;
  }

  @media screen and (min-width: ${desktop}) {
    grid-template-columns: repeat(2, 400px);
  }
  @media screen and (min-width: ${wide}) {
    grid-template-columns: repeat(4, 280px);
  }
`

const Tiles = ({ items }: Props) => {
  return (
    <div className={cx(style)}>
      {items.map((item: partnerConfig, i) => (
        <Tile key={`${item.partnerRoute}${i}`} {...item} />
      ))}
    </div>
  )
}

export default Tiles
