import Amplify, { Hub, HubCapsule, Logger } from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import { parse } from 'date-fns'

const awsConfig = (userPoolId: string, clientId: string) => ({
  Auth: {
    region: 'ap-southeast-2',
    userPoolId: userPoolId,
    userPoolWebClientId: clientId,
    mandatorySignIn: false,
  },
})

const authConfig = (domain: string, partnerRoute: string) => ({
  domain: domain,
  scope: ['email', 'profile', 'openid'],
  redirectSignIn: `${window.location.origin}/partner/${partnerRoute}`,
  redirectSignOut: `${window.location.origin}/auth/loggedOut`,
  responseType: 'token',
})

type AuthResult = {
  username: string
  salesId: string
  location: string
  state: string
  expiry: string
  token: string
}

export const getAuthResult = (): AuthResult => {
  const authResult = localStorage.getItem('authResult')
  if (!authResult) {
    return {} as AuthResult
  } else {
    return JSON.parse(authResult) as AuthResult
  }
}

const setAuthResult = (authResult: AuthResult) => {
  localStorage.setItem('authResult', JSON.stringify(authResult))
}

export const clearAuthResult = () => {
  localStorage.setItem('authResult', JSON.stringify({}))
}

export const isSignedIn = () => {
  const authResult = getAuthResult()
  const now = new Date()
  const expiry = parse(authResult.expiry, 't', now)
  const expired = expiry <= now
  return !!(authResult.token && !expired)
}

export const login = async (returnUrl: string = '') => {
  const currentPath = window.location.pathname
  localStorage.setItem('entry_point', returnUrl || currentPath)
  clearAuthResult()
  return Auth.federatedSignIn()
}

export const logout = () => {
  clearAuthResult()
  return Auth.signOut()
}

const navigateToEntryPoint = () => {
  const entryPoint = localStorage.getItem('entry_point') || '/'
  window.history.replaceState(entryPoint, '')
}

const createAuthResult = (token: string, profile: any) => {
  let authResult: AuthResult = {
    username: profile['cognito:username'],
    salesId: profile['custom:sales_agent_id'],
    location: profile['custom:location'],
    state: profile['custom:state'],
    expiry: profile['exp'],
    token: token,
  }
  return authResult
}

const logger = new Logger('Affinity')

const listener = () => ({ payload: { event, data } }: HubCapsule) => {
  switch (event) {
    case 'signIn':
      logger.info('user signed in')
      let { signInUserSession: session } = data
      const authResult = createAuthResult(
        session.idToken.jwtToken,
        session.idToken.payload
      )
      setAuthResult(authResult)
      navigateToEntryPoint()
      break
  }
}

export type CognitoConfig = {
  domain: string
  userPoolId: string
  clientId: string
}

export const init = (cognitoConfig: CognitoConfig, partnerRoute: string) => {
  Amplify.configure(
    awsConfig(cognitoConfig.userPoolId, cognitoConfig.clientId)
  )
  Auth.configure({ oauth: authConfig(cognitoConfig.domain, partnerRoute) })
  Hub.listen('auth', listener())
}
